import React, { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import PeopleIcon from "@mui/icons-material/People";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Axios from "../redux/axios";

const Status = () => {
  const [userData, setUserData] = useState([]);
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };
  // useEffect(() => {
  //   Axios.get("/get-all-users", config)
  //     .then((res) => {
  //       setUserData(res.data.users);
  //     })
  //     .catch((err) => {
  //       if (err.response.data.message === "jwt expired") {
  //         localStorage.clear();
  //       } else if (err.response.data.message === "invalid token") {
  //         localStorage.clear();
  //       } else if (err.response.data.message === "jwt malformed") {
  //         localStorage.clear();
  //       }
  //     });
  // }, []);
  useEffect(() => {
    Axios.get("/admin-panel-details", config)
      .then((res) => {
        setUserData(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="widget">
        <div className="left">
          <span className="title">USERS</span>
          <span className="counter">
            <PeopleIcon
              style={{
                color: "#000",
              }}
            />
            {userData.user}
          </span>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpIcon />
          </div>
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        </div>
      </div>

      <div className="widget">
        <div className="left">
          <span className="title">Total Bets Amount</span>
          <span className="counter">
            <MonetizationOnIcon
              style={{
                color: "#000",
              }}
            />{" "}
            {userData.totalBetAmount}
          </span>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpIcon />
          </div>

          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        </div>
      </div>

      <div className="widget">
        <div className="left">
          <span className="title">PROFITE</span>
          <span className="counter">
            {" "}
            <MonetizationOnIcon
              style={{
                color: "#000",
              }}
            />{" "}
            {userData.totalProfit}
          </span>
        </div>
        <div className="right">
          <div className="percentage positive">
            <KeyboardArrowUpIcon />
          </div>

          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Status;
