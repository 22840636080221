import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar";
import { onLogin } from "../../redux/homeAction";

const Login = ({ openGooglePopUp, openFacebookPopUp }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const submitLoginHandler = () => {
    const data = {
      email: email,
      password: password,
    };
    if (!email) {
      toast.error("Enter a Email Address");
    } else if (!regex.test(email)) {
      toast.error("Enter a Correct Email Address");
    } else if (!password) {
      toast.error("Enter a Password");
    } else if (password.length < 8) {
      toast.error("The password must be at least 8 characters");
    } else if (!strongRegex.test(password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else {
      dispatch(onLogin(data, navigate));
    }
  };

  return (
    <div>
      <Navbar />
      <section id="Login" data-aos={"fade-left"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Login to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/login_right.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" />
                    </Link>
                  </div>
                  <div className="login_informention position-relative">
                    <img src="./images/login_bg.png" className="login_bg_IMG" />
                    <div className="login_content login_content2">
                      <div className="form-section">
                        <div className="form-group floating">
                          <div className="mail_img">
                            <img src="./images/mail.png" />
                          </div>
                          <input
                            type="email"
                            className="form-control floating"
                            id="usr"
                            onChange={(e) => setEmail(e.target.value)}
                            required
                          />
                          <label htmlFor="usr">Email address</label>
                        </div>
                        <div className="form-group floating">
                          <Link
                            to="/forgetpassword"
                            className="forgot_password"
                          >
                            <p>Forgot Password</p>
                          </Link>
                          <div className="mail_img">
                            <img src="./images/password_vector.png" />
                          </div>
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control floating"
                            id="usrpassword"
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                          <label htmlFor="usr">Password</label>
                          {showPassword === false ? (
                            <img
                              src="./images/hide_password.png"
                              id="showPassword"
                              onClick={() => setShowPassword(true)}
                            />
                          ) : (
                            <img
                              src="./images/show_password.png"
                              id="showPassword"
                              onClick={() => setShowPassword(false)}
                            />
                          )}
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <button
                            className="main-btn login-btn next-page"
                            onClick={submitLoginHandler}
                          >
                            <img src="./images/next_page_vector.png" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="login_option login_option2">
                      <div className="login_option_content">
                        <h5>OR</h5>
                        <h5>Login with Social Account</h5>
                        <div className="support_btns">
                          <button
                            className="main-btn google_btn"
                            onClick={openGooglePopUp}
                          >
                            <img src="./images/google_vector.png" />
                            <h5>Login With Google</h5>
                          </button>
                          <button
                            className="main-btn facebook_btn"
                            onClick={openFacebookPopUp}
                          >
                            <img src="./images/facebook_vector.png" />
                            <h5>facebook</h5>
                          </button>
                        </div>
                        <h5 className="text-center">
                          Don't have an Account ?
                          <span className="text-yellow">
                            <Link to="/register">Register here</Link>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
