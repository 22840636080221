import SideBar from "../SideBar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import AdminHeader from "../common/AdminHeader";
import { useState } from "react";

const New = () => {
  const [file, setFile] = useState("");

  return (
    <>
      <AdminHeader />
      <div className="container-fluid bg-color-change">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="top">
                <h1>Add New User</h1>
              </div>
              <div className="bottom">
                <div className="left">
                  <img
                    src={
                      file
                        ? URL.createObjectURL(file)
                        : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                    }
                  />
                </div>
                <div className="right">
                  <form>
                    <div className="formInput">
                      <label htmlFor="file">
                        Image:{" "}
                        <DriveFolderUploadOutlinedIcon className="icon" />
                      </label>
                      <input
                        type="file"
                        id="file"
                        onChange={(e) => setFile(e.target.files[0])}
                        style={{ display: "none" }}
                      />
                    </div>

                    {/* {userInputs &&
                      userInputs.map((input) => (
                        <div className="formInput" key={input.id}>
                          <label>{input.label}</label>
                          <input
                            type={input.type}
                            placeholder={input.placeholder}
                          />
                        </div>
                      ))} */}
                    <button>Send</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default New;
