import React, { useEffect } from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { onOneUsersData, onPaymentDetails } from "../../../../redux/homeAction";

const BankAccount = ({
  onChangeEventHandler,
  value,
  onChangeImage,
  imageFile,
  setImageFile,
  button,
  amount,
  setAmount,
  setButton,
  getPaymentNumber,
  showPayment,
}) => {
  const dispatch = useDispatch();
  const getOneUserData = useSelector((state) => state.home.getOneUserData);
  const userId = JSON.parse(localStorage.getItem("user-details"));

  const copyClipBoardNumber = (data) => {
    copy(data);
    toast.success(`coppied`);
  };

  useEffect(() => {
    const data = {
      _id: userId && userId._id,
    };
    dispatch(onOneUsersData(data));
  }, []);

  const submitPaymentDetails = () => {
    const formData = new FormData();
    formData.append("amount", amount);
    formData.append("Userid", userId && userId._id);
    formData.append("UtrNumber", value.utrNumber);
    formData.append("BankHolderName", value.bankName);
    formData.append("BankAccountNumber", value.accountNumber);
    formData.append("showPaymentMethodImg", showPayment);
    formData.append("testImg", imageFile);
    formData.append("UserBonosId", userId && userId.userReferralId);
    formData.append(
      "uniqueReferralId",
      getOneUserData && getOneUserData.uniqueReferralId
    );
    formData.append("status", "pending");

    if (!amount) {
      toast.error("Please enter amount");
    } else if (amount < 300) {
      toast.error("Please enter amount 300 more");
    } else if (!value.bankName) {
      toast.error("Please Fill Bank Account Name");
    } else if (value.accountNumber.length < 9) {
      toast.error("The Account number must be at least 9-17 digits");
    } else if (!value.utrNumber) {
      toast.error("Please enter a UTR Number");
    } else if (!imageFile) {
      toast.error("Please upload transaction image");
    } else {
      dispatch(onPaymentDetails(formData));
      setButton(true);
    }
  };

  return (
    <>
      <div
        className="deposit-right deposit-righttt position-relative"
        id="depoidid2"
      >
        <div className="deposit-right-top d-flex justify-content-between align-items-center">
          <h4>Specify the Transaction ID</h4>
          <div className="id">ID : 11250422</div>
        </div>
        <p>For deposit please send the money to the following credentials</p>
        <div className="deposit-detail position-relative">
          <img src="./images/deposit_right.png" className="deposit_right_img" />
          <h5>1. Copy and Send Money</h5>

          <div className="bank_account_style">
            <div className="bank_account_name">
              <div className="">
                <p>Bank Name :</p>
                <span>
                  {getPaymentNumber &&
                    getPaymentNumber.Bank &&
                    getPaymentNumber.Bank.bankName}
                </span>
              </div>
            </div>
            <div className="bank_account_name">
              <div className="">
                <p>Name :</p>
                <span>
                  {getPaymentNumber &&
                    getPaymentNumber.Bank &&
                    getPaymentNumber.Bank.holderName}
                </span>
              </div>
            </div>
            <div className="bank_account_name">
              <div className="">
                <p>IFSC Code :</p>
                <span>
                  {getPaymentNumber &&
                    getPaymentNumber.Bank &&
                    getPaymentNumber.Bank.ifscCode}
                </span>
              </div>
              <button
                className="main-btn login-btn copy-btn"
                onClick={() =>
                  copyClipBoardNumber(
                    getPaymentNumber &&
                      getPaymentNumber.Bank &&
                      getPaymentNumber.Bank.ifscCode
                  )
                }
              >
                <img src="./images/cope_ventor.png" />
                Copy
              </button>
            </div>
            <div className="bank_account_name">
              <div className="">
                <p>Account Number :</p>
                <span>
                  {getPaymentNumber &&
                    getPaymentNumber.Bank &&
                    getPaymentNumber.Bank.accountNumber}
                </span>
              </div>

              <button
                className="main-btn login-btn copy-btn"
                onClick={() =>
                  copyClipBoardNumber(
                    getPaymentNumber &&
                      getPaymentNumber.Bank &&
                      getPaymentNumber.Bank.accountNumber
                  )
                }
              >
                <img src="./images/cope_ventor.png" />
                Copy
              </button>
            </div>
          </div>
          <div className="upi_id">
            <img src="./images/bank_icon.png" style={{ width: "45px" }} />
            <img src="./images/dot_middle_line.png" />
            <h5>
              <span className="upi_text_rupay">₹</span>
              <input
                type="text"
                className="input"
                placeholder="300.00"
                name="amount"
                value={amount === null ? "" : amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </h5>

            <div className="payment-range">
              <p>₹ 300 - ₹ 70,000 mix*</p>
            </div>
          </div>
          <h5 className="mt-4 mb-3">2. Add Transaction Details</h5>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="bankName"
              value={value.bankName}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">Bank Holder Name</label>
          </div>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="accountNumber"
              value={value.accountNumber}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">Your Account Number</label>
          </div>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="utrNumber"
              value={value.utrNumber}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">Your 12 Digits UTR Number</label>
          </div>
          <div className="form-group floating uploadImg">
            {imageFile ? (
              <>
                <p className="cross-icon-change-p">
                  {imageFile && imageFile.name}
                </p>
                <img
                  src="./images/model_close.png"
                  className="cross-icon-change"
                  onClick={() => {
                    setImageFile(null);
                    toast.success("transaction image removed successfully ");
                  }}
                />
              </>
            ) : (
              <>
                <div className="mail_img" id="mailIMGShoe">
                  <img src="./images/photo.png" />
                  <div className="uploadimageshow">
                    <img src="#" id="imgshow" />
                    <img
                      src="./images/uploadImg_vector.png"
                      className="uploadimg_vector"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control floating uploadImgTExt"
                  id="uloadimgName"
                  name="imgmessage"
                  value={value.imgmessage}
                  onChange={(e) => onChangeEventHandler(e)}
                  required
                />
                <label htmlFor="usr">
                  Payment Transaction Screenshot Attached
                </label>
                <div className="uploadClick">
                  <input type="file" id="FileClick" onChange={onChangeImage} />
                  <div className="mail_img" id="upload-file">
                    <img src="./images/upload-image.png" />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="back_payment back-arrow">
            {button === true ? (
              <button className="main-btn login-btn next-page">
                <img src="./images/next_page_vector.png" />
              </button>
            ) : (
              <button
                className="main-btn login-btn next-page"
                onClick={submitPaymentDetails}
              >
                <img src="./images/next_page_vector.png" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BankAccount;
