import { Button } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import Axios from "../../redux/axios";
import { Helmet } from "react-helmet";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const AdminLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const adminLogin = (e) => {
    e.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    if (!email) {
      toast.error("please enter email address");
    } else if (!regex.test(email)) {
      toast.error("Enter a Correct Email Address");
    } else if (!password) {
      toast.error("please enter password");
    } else if (password.length < 8) {
      toast.error("The password must be at least 8 characters");
    } else if (!strongRegex.test(password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else {
      Axios.post(`/admin-login`, data)
        .then((res) => {
          toast.success(res.data.message);
          setTimeout(() => {
            navigate("/dashboard/welcome-page");
            localStorage.setItem("adminToken", res.data.token);
            localStorage.setItem("adminDetils", JSON.stringify(res.data.msg));
            window.location.reload();
          }, 500);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const stylish = {
    password: {
      position: "relative",
      width: "100%",
    },
    passwordopen: {
      position: "absolute",
      right: "20px",
      top: "20px",
      cursor: "pointer",
    },

    space: {
      padding: "20px 20px",
      border: "2px solid #2a3763",
      width: "100%",
      borderRadius: "30px",
      fontSize: "14px",
    },
  };

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <form onSubmit={adminLogin}>
        <div className="admin_login_page" data-aos={"fade-up"}>
          <div className="container">
            <div className="row admin_login_page_center">
              <div className="col-lg-6 admin_login_page_bg">
                <h1>Flex Aviator</h1>
                <h6>Admin Login</h6>

                <div className="mb-4 mt-5" style={stylish.password}>
                  <input
                    type="email"
                    value={email}
                    placeholder="Enter a email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={stylish.space}
                  />
                </div>
                <div className="" style={stylish.password}>
                  <input
                    type={passwordShow === true ? "text" : "password"}
                    required
                    value={password}
                    placeholder="Enter a password"
                    onChange={(e) => setPassword(e.target.value)}
                    style={stylish.space}
                  />
                  {passwordShow === true ? (
                    <VisibilityOutlinedIcon
                      className="icon"
                      style={stylish.passwordopen}
                      onClick={() => setPasswordShow(false)}
                    />
                  ) : (
                    <VisibilityOffOutlinedIcon
                      className="icon"
                      style={stylish.passwordopen}
                      onClick={() => setPasswordShow(true)}
                    />
                  )}
                </div>
                <div className="admin_login_center">
                  <Button variant="contained" color="success" type="submit">
                    Login
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default AdminLogin;
