import React from "react";
import { Link } from "react-router-dom";
import LogoImage from "../Flynwin_logo.png";

const AdminHeader = () => {
  return (
    <>
      <section id="Header" data-aos={"fade-down"}>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid d-flex justify-content-between">
            <div className="navbar-logo">
              <Link to="/admin/dashboard" className="navbar-brand-admin ">
                <img src={LogoImage} alt="logo" />
              </Link>
            </div>

            <div className="navbar-right-display">
              <div className="navbar-right ">
                <h3 className="navbar-right-admin">DashBoard</h3>
              </div>
            </div>
          </div>
        </nav>
      </section>
    </>
  );
};

export default AdminHeader;
