import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import Loader from "../Loader/Loader";
import SideBar from "../SideBar";

const SubAdminList = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };
  useEffect(() => {
    setLoader(true);
    Axios.get("/admin-find-role", config)
      .then((res) => {
        setUserData(res.data.msg);
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  const columns = useMemo(() => [
    { field: "email", headerName: "email", width: 200 },
    { field: "Role", headerName: "Role", width: 450 },
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => handleChangeDeleteUser(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
    { field: "_id", hide: true },
  ]);

  const handleChangeDeleteUser = (id) => {
    Axios.delete(`/sub-admin-delete/` + id, config)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {});
  };

  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          {loader === true ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-2">
                <SideBar />
              </div>
              <div className="col-lg-10">
                <div className="datatable">
                  <div className="datatableTitle">Sub Admin List</div>
                  <DataGrid
                    className="datagrid"
                    columns={columns}
                    rows={rows}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SubAdminList;
