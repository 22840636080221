import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import AdminHeader from "./common/AdminHeader";
import SideBar from "./SideBar";
import Button from "@mui/material/Button";
import { Helmet } from "react-helmet";
import Axios from "../redux/axios";
import { toast } from "react-toastify";
import { SocketContext } from "../SocketContext/SocketContext";
import { DataGrid } from "@mui/x-data-grid";

const GamePlay = () => {
  const [value, setValue] = useState("");
  const [gameId, setGameId] = useState("");
  const [totalBets, setTotalBets] = useState("");
  const [profitValue, setProfitValue] = useState("");
  const [totalBetsAmount, setTotalBetsAmount] = useState("");
  const [profitData, setProfitData] = useState([]);
  const [liveMultiplierSwitch, setLiveMultiplierSwitch] = useState(false);
  const [count, setCount] = useState(0);
  const [globalTimeNow, setGlobalTimeNow] = useState(0);
  const [waitingVisible, setWaitingVisible] = useState(false);
  const [countColor, setCountColor] = useState("#fbdc4b");
  const [bBettingPhase, setbBettingPhase] = useState(false);
  const [statusUpdate, setStatusUpdate] = useState("");
  const { socket } = useContext(SocketContext);

  const multiplierCount = useRef([]);
  const timeCount_xaxis = useRef([]);
  const realCounter_yaxis = useRef(5);
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  const profiteGame = (e) => {
    const data = {
      profitNumber: value,
    };
    e.preventDefault();
    if (!value) {
      toast.error("please enter profit");
    } else {
      Axios.post("/profit-create", data, config).then((res) => {
        setValue("");
        toast.success(res.data.msg);
      });
    }
  };

  useEffect(() => {
    Axios.get("/one-data-find", config)
      .then((res) => {
        if (res.data.status === true) {
          setTotalBets(res.data.message.totalLength);
          setTotalBetsAmount(res.data.message.totalAmount);
          setProfitValue(res.data.message.profitAmount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // socket.on("total-bets-find", (totalAmount, totalLength) => {

    // });
  }, [socket, gameId]);

  useEffect(() => {
    Axios.get("/profit-number", config)
      .then((res) => {
        setProfitData(res.data.profit);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [value, statusUpdate]);

  const rocketBlast = (e) => {
    e.preventDefault();
    const data = {
      gameId: gameId,
    };
    Axios.post("/blastrocket", data, config).then((res) => {
      toast.success("rocket blast done");
      window.location.reload();
    });
  };

  useEffect(() => {
    socket.on("start_multiplier_count", function () {
      setGlobalTimeNow(Date.now());
      setLiveMultiplierSwitch(true);
      setWaitingVisible(false);
      setCountColor("#fbdc4b");
    });

    socket.on("current-game-id", (gameId) => {
      setGameId(gameId);
    });

    socket.on("stop_multiplier_count", (dataUser) => {
      localStorage.setItem("end-count", dataUser.endTime);
      setCount(dataUser.endTime);
      setLiveMultiplierSwitch(false);
      setCountColor("red");
    });

    socket.on("start_betting_phase", function (data) {
      // setCount("Starting...");
      setGlobalTimeNow(Date.now());
      setWaitingVisible(true);
      setbBettingPhase(true);
      setCount(null);
      // retrieve_active_bettors_list();
      multiplierCount.current = [];
      timeCount_xaxis.current = [];
    });

    socket.on("receive_live_betting_table", (data) => {
      setCount(data);
      data = JSON.parse(data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // console.log(totalBetsAmount, "totalBetsAmount");
  // console.log(totalBets);

  //counter
  useEffect(() => {
    let gameCounter = null;
    if (liveMultiplierSwitch) {
      setCount("1.00");
      gameCounter = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 600.0;
        setCount((1.0045 * Math.pow(1.0418, time_elapsed)).toFixed(2));

        if (multiplierCount.current.length < 1) {
          multiplierCount.current = multiplierCount.current.concat([1]);
          timeCount_xaxis.current = timeCount_xaxis.current.concat([0]);
        }
        if (realCounter_yaxis.current % 5 == 0) {
          multiplierCount.current = multiplierCount.current.concat([
            (1.0045 * Math.pow(1.0418, time_elapsed)).toFixed(2),
          ]);
          timeCount_xaxis.current = timeCount_xaxis.current.concat([
            time_elapsed,
          ]);
        }
        realCounter_yaxis.current += 1;
      }, 1);
    }
    return () => {
      clearInterval(gameCounter);
    };
  }, [liveMultiplierSwitch]);

  useEffect(() => {
    let bettingInterval = null;

    if (bBettingPhase) {
      bettingInterval = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        let time_remaining = (3.8 - time_elapsed).toFixed(2);
        // setBettingPhaseTime(time_remaining);
        setWaitingVisible(true);
        if (time_remaining < 0) {
          setbBettingPhase(false);
          setWaitingVisible(false);
        }
      }, 10);
    }
    return () => {
      clearInterval(bettingInterval);
      // setBettingPhaseTime("Starting...");
      setWaitingVisible(false);
    };
  }, [bBettingPhase]);

  let stopBetCount = localStorage.getItem("end-count");

  useEffect(() => {
    setCount(stopBetCount);
  }, [stopBetCount]);

  useEffect(() => {
    get_game_status();
  }, []);

  const get_game_status = () => {
    Axios.get("/get_game_status").then((res) => {
      if (res.data.phase === "betting_phase") {
        setGlobalTimeNow(res.data.info);
        setbBettingPhase(true);
        setWaitingVisible(true);
      } else if (res.data.phase === "game_phase") {
        setGlobalTimeNow(res.data.info);
        setLiveMultiplierSwitch(true);
        setCountColor("#fbdc4b");
      }
    });
  };

  const columns = useMemo(() => [
    { field: "profitNumber", headerName: "profit", width: 230 },
    { field: "_id", hide: true },
  ]);

  const rows = useMemo(
    () =>
      profitData && profitData.map((row, index) => ({ ...row, id: row._id })),
    [profitData]
  );

  const deleteProfit = (id) => {
    Axios.delete(`/profit-number-delete/` + id, config)
      .then((res) => {
        if (res.data.status) {
          toast.success(res.data.message);
          setStatusUpdate(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <div
              className="deleteButton"
              onClick={() => deleteProfit(params.row._id)}
            >
              Delete
            </div>
            {params.row.updatedIndex === 1 && (
              <div className="current_profit">
                <i className="fa fa-star"></i>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="blast_animtion">
                <div className="row">
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Current Counter</p>
                      {waitingVisible ? (
                        <span>loading....</span>
                      ) : (
                        <>
                          {count ? (
                            <span style={{ color: countColor }}>{count} x</span>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Game Id</p>
                      <span>{gameId}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Blast Buttton</p>
                      {gameId && <button onClick={rocketBlast}>Blast</button>}
                    </div>
                  </div>
                </div>
              </div>
              <div className="blast_animtion">
                <div className="row">
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Total Bets</p>
                      <span>{totalBets}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Total Amount </p>
                      <span>{totalBetsAmount}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="blast_animtion_color">
                      <p>Total Amount Profit </p>
                      <span>{profitValue}</span>
                    </div>
                  </div>
                  <div className="col-md-4">
                    {/* <div className="blast_animtion_color">
                      <p>Blast Buttton</p>
                      {gameId && <button onClick={rocketBlast}>Blast</button>}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="gameplay-space">
                <form onSubmit={profiteGame}>
                  <div className="gameplay-space-flex">
                    <input
                      type="number"
                      placeholder="Enter Game Play Percentage"
                      className=""
                      onChange={(e) => setValue(e.target.value)}
                      value={value}
                      required
                    />
                  </div>
                  <div className="">
                    <Button type="submit" variant="contained" color="success">
                      Add
                    </Button>
                  </div>
                </form>
              </div>

              <h2
                style={{ color: "#000", fontSize: 26, fontWeight: "600" }}
                className="mb-3"
              >
                Profit Values
              </h2>
              <DataGrid
                className="datagrid"
                columns={columns.concat(actionColumn)}
                rows={rows}
                pageSize={9}
                rowsPerPageOptions={[9]}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GamePlay;
