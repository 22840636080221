import React, { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const LeftSide = ({
  rocket,
  flyAnimationTimeX,
  flyAnimationTimeY,
  allBets,
  countColor,
  modelShow,
  model2Show,
  // left button
  betBtnVisibility,
  cashoutbetOnGame,
  cancelBtnVisibility,
  count,
  cashoutBtnVisibility,
  waitingVisible,
  cancelbetOnGame,
  betOnGame,
  setCashPlus,
  cashPlus,
  setAutoBet,
  setAuto2Bet,
  presentPlus,
  present2Plus,
  setPresentPlus,
  setPresent2Plus,
  //right button
  cashoutBtn2Visibility,
  setAutoBetSecond,
  cancelBtn2Visibility,
  betBtn2Visibility,
  setCash2Plus,
  cash2Plus,
  cashoutbetOn2Game,
  setAutoBet2Second,
  cancelbetOn2Game,
  betOn2Game,
}) => {
  const minAmount = 1;
  const maxAmount = 10000;
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const openBets = () => setShow(true);
  const closeBets = () => setShow(false);

  const CashIncreament = () => {
    setCashPlus(parseInt(cashPlus) + 10);
  };
  const PresentIncreament = () => {
    setPresentPlus((parseFloat(presentPlus) + 0.01).toFixed(2));
  };

  const CashDecriment = () => {
    if (cashPlus > 10) {
      setCashPlus(parseInt(cashPlus) - 10);
    }
  };

  const PresentDecriment = () => {
    if (presentPlus > 1.01) {
      setPresentPlus((parseFloat(presentPlus) - 0.01).toFixed(2));
    }
  };

  // right button
  const CashIncreament2 = () => {
    setCash2Plus(parseInt(cash2Plus) + 10);
  };

  const PresentIncreament2 = () => {
    setPresent2Plus((parseFloat(present2Plus) + 0.01).toFixed(2));
  };

  const CashDecriment2 = () => {
    if (cash2Plus > 10) {
      setCash2Plus(parseInt(cash2Plus) - 10);
    }
  };
  const PresentDecriment2 = () => {
    if (present2Plus > 1.01) {
      setPresent2Plus((parseFloat(present2Plus) - 0.01).toFixed(2));
    }
  };

  const verifyAmount = (amount) => {
    const validated = /^[0-9\b]+$/;
    if (amount === "" || validated.test(amount)) {
      const value = Math.max(minAmount, Math.min(maxAmount, Number(amount)));
      setCashPlus(value);
    }
  };

  const verify2Amount = (amount) => {
    const validated = /^[0-9\b]+$/;
    if (amount === "" || validated.test(amount)) {
      const value = Math.max(minAmount, Math.min(maxAmount, Number(amount)));
      setCash2Plus(value);
    }
  };

  const cashoutValueCheck = (cashout) => {
    const validated = cashout.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      setPresentPlus(cashout);
    }
  };

  const cashoutValue2Check = (cashout) => {
    const validated = cashout.match(/^(\d*\.{0,1}\d{0,2}$)/);
    if (validated) {
      setPresent2Plus(cashout);
    }
  };

  const userLogin = localStorage.getItem("userToken");

  const netProblem = localStorage.getItem("net-problem");

  return (
    <>
      <div className="col-lg-8 col-md-12 h-100" data-aos={"fade-right"}>
        <div
          className={`${show === true ? "active" : "battings-btns"}`}
          id="battingsbtns"
        >
          <h4 id="battingTitle" className={`${show === true ? "active" : ""}`}>
            Round Of History
            <div className="batting-btns-bar" id="histroy_batting_close">
              <button className="main-btn login-btn" onClick={closeBets}>
                <img src="./images/PINK_timer.png" className="login-btn-img" />
                <span id="BattingBattingBtn">Close</span>
              </button>
            </div>
          </h4>
          <div
            className={` ${
              show === true ? "batting-btns-list_active" : "batting-btns-list"
            }`}
            id="BattingLists"
          >
            {allBets &&
              allBets.map((data, i) => (
                <button
                  className={`main-btn battling-button  ${
                    data.random_number > 2 ? "twoup" : "twodown"
                  }`}
                  key={i}
                >
                  {data.random_number}
                </button>
              ))}
          </div>
          <div
            className={`${
              show === true ? "batting-btns-bar_close" : "batting-btns-bar"
            }`}
            id="histroy_batting"
          >
            <button className="main-btn login-btn" onClick={openBets}>
              <img src="./images/btn-timing.png" className="login-btn-img" />
              <span id="BattingBattingBtn">R-H</span>
              <img
                src="./images/drop-down-vector.png"
                className="dropdown-vector"
              />
            </button>
          </div>
        </div>
        <div id="Batting_section" className="batting-container">
          <div className={`net-connection ${netProblem ? "active" : ""}`}>
            <div className="net-connection-icon">
              <i className="fa fa-wifi"></i>
            </div>
            <div className="net-connection-show">
              <h4>There is no Internet</h4>
              <p>Trying to connect again</p>
            </div>
          </div>
          <div className="batiing-video position-relative ">
            {waitingVisible === true ? (
              <div className="loading-section">
                <>
                  <div className="loading-reaction">
                    <div className="loadimg-images">
                      <img
                        src="./images/loading.png"
                        className="loading loadingOnProgress"
                      />
                      <img
                        src="./images/loading-rocket.png"
                        className="loading-rocket loadingRocketSuccessfull_"
                      />
                    </div>
                  </div>
                  <h1 className="waiting_next_round">
                    Waiting For The Next Round
                  </h1>
                  <div className="loading_proress">
                    <div className="progress-wrapper">
                      <div className="progress1"></div>
                    </div>
                    <input
                      type="range"
                      id="loadind_slider_input"
                      min="0"
                      max="100"
                      list="loading_input"
                    />
                  </div>
                </>
              </div>
            ) : (
              <>
                <div className="video_section video_section_animate">
                  <div className="sc-kstqJO video_section_bg_img videosection_img" />
                  <div className="sc-hBEYId animation_cloud_bg" />
                  <div className="sc-iBPTik animation_bottom_lines" />
                  <div className="sc-iBPTik animation_left_lines" />
                  <div className="sc-kEjbQP animation_animate">
                    <div
                      className={`fly ${rocket ? "complate" : ""}`}
                      style={{
                        transform: `translateX(${flyAnimationTimeX}%) translateY(${flyAnimationTimeY}%)`,
                      }}
                    >
                      <div className="sc-fubCzh fly_imgs_box">
                        <img
                          src="./images/animation_fire.gif"
                          className="sc-pGacB fly_main"
                        />
                        <img
                          src="./images/men.gif"
                          className="sc-jrAFXE fnZiND fly_main_fire"
                        />
                      </div>
                    </div>
                    <div className="fly_line_animation">
                      {/* <img src="./images/line_fly.svg"  /> */}
                      {/* <SvgIcon /> */}
                    </div>
                  </div>
                </div>

                {count ? (
                  <div className="count-position">
                    <h2
                      className=""
                      style={{ color: countColor, fontSize: 75 }}
                    >
                      {count}x
                    </h2>
                    {countColor === "red" ? <h4>FLEW AWAY!</h4> : ""}
                  </div>
                ) : (
                  ""
                )}
              </>
            )}
          </div>
          <div className="batting-detail">
            <div className="row g-5">
              <div className="col-md-6 d-flex justify-content-between g-3">
                <div className="right_bg">
                  <div className="bet_content-right">
                    <div className="batting_count-detail">
                      <div className="batting_content_detail d-flex display-column gy-3">
                        <div className="play_batting d-flex justify-content-between">
                          <div className="play_batting-checkbox d-flex display-column gy-2">
                            <p>Auto</p>
                            <label
                              className="form-label lms_checkbox_1"
                              htmlFor="auto-1"
                            >
                              <input
                                type="checkbox"
                                id="auto-1"
                                onChange={(e) => {
                                  if (userLogin) {
                                    setAutoBet(e.target.checked);
                                  } else {
                                    toast.error(
                                      "Please login First and Play Game"
                                    );
                                    navigate("/login");
                                  }
                                }}
                              />
                              <div className="slider-check round"></div>
                            </label>
                          </div>
                          <div className="play_batting_count">
                            <p>Bet</p>
                            <div className="play_batting_inr">
                              <div className="inr-plus" onClick={CashDecriment}>
                                -
                              </div>

                              <div className="tag-bet-input">
                                <input
                                  className="tag-bet-input-div"
                                  onChange={(e) => verifyAmount(e.target.value)}
                                  value={cashPlus}
                                  maxLength="5"
                                />
                                <div className="tag-bet-input-price">
                                  <div className="px-1">{cashPlus}</div>
                                  <div>INR</div>
                                </div>
                              </div>

                              <div
                                className="inr-minus"
                                onClick={CashIncreament}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="play-batting-btns d-flex justify-content-between">
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCashPlus(parseInt(cashPlus) + 150)
                            }
                          >
                            +150
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCashPlus(parseInt(cashPlus) + 200)
                            }
                          >
                            +200
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCashPlus(parseInt(cashPlus) + 250)
                            }
                          >
                            +250
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCashPlus(parseInt(cashPlus) + 300)
                            }
                          >
                            +300
                          </button>
                        </div>
                      </div>
                      <div className="play_batting d-flex justify-content-between">
                        <div className="play_batting-checkbox d-flex display-column gy-2">
                          <p>Auto</p>
                          <label
                            className="form-label lms_checkbox_1"
                            htmlFor="auto_2"
                          >
                            <input
                              type="checkbox"
                              id="auto_2"
                              onChange={(e) => setAuto2Bet(e.target.checked)}
                            />
                            <div className="slider-check round"></div>
                          </label>
                        </div>
                        <div className="play_batting_count">
                          <p>Collect</p>
                          <div className="play_batting_inr">
                            <div
                              className="inr-plus"
                              onClick={PresentDecriment}
                            >
                              -
                            </div>

                            <div className="tag-bet-input">
                              <input
                                className="tag-bet-input-div"
                                maxLength="4"
                                value={presentPlus}
                                onChange={(e) =>
                                  cashoutValueCheck(e.target.value)
                                }
                                disabled
                              />
                              <div
                                className="tag-bet-input-price"
                                style={{ left: "-8px" }}
                              >
                                <div className="px-1">{presentPlus}</div>
                                <div>x</div>
                              </div>
                            </div>
                            <div
                              className="inr-minus"
                              onClick={PresentIncreament}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bet_content-btn">
                      {betBtnVisibility === true ? (
                        <>
                          {modelShow === true ? (
                            <button
                              className="main-btn bet-btn"
                              data-bs-toggle="modal"
                              data-bs-target="##AmountDeposite"
                            >
                              BET
                            </button>
                          ) : (
                            <button
                              className="main-btn bet-btn"
                              onClick={betOnGame}
                            >
                              BET
                            </button>
                          )}
                        </>
                      ) : cancelBtnVisibility === true ? (
                        <button
                          className="main-btn bet-btn bet_cancel"
                          onClick={cancelbetOnGame}
                        >
                          Cancel
                        </button>
                      ) : cashoutBtnVisibility === true ? (
                        <button
                          className="bet_checkout"
                          onClick={cashoutbetOnGame}
                        >
                          {(count * cashPlus).toFixed(2)}x CASHOUT
                        </button>
                      ) : (
                        ""
                      )}
                      {/* {cancelBtnVisibility === true ? (
                        <button
                          className="main-btn bet-btn bet_cancel"
                          onClick={cancelbetOnGame}
                        >
                          Cancel
                        </button>
                      ) : (
                        ""
                      )} */}
                      {/* {cashoutBtnVisibility === true ? (
                        <button
                          className="bet_checkout"
                          onClick={cashoutbetOnGame}
                        >
                          {(count * cashPlus).toFixed(2)}x CASHOUT
                        </button>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex justify-content-between g-3">
                <div className="right_bg">
                  <div className="bet_content-right">
                    <div className="batting_count-detail">
                      <div className="batting_content_detail d-flex display-column gy-3">
                        <div className="play_batting d-flex justify-content-between">
                          <div className="play_batting-checkbox d-flex display-column gy-2">
                            <p>Auto</p>
                            <label
                              className="form-label lms_checkbox_1"
                              htmlFor="auto-4"
                            >
                              <input
                                type="checkbox"
                                id="auto-4"
                                onChange={(e) => {
                                  if (userLogin) {
                                    setAutoBetSecond(e.target.checked);
                                  } else {
                                    toast.error(
                                      "Please login First and Play Game"
                                    );
                                    navigate("/login");
                                  }
                                }}
                              />
                              <div className="slider-check round"></div>
                            </label>
                          </div>
                          <div className="play_batting_count">
                            <p>Bet</p>
                            <div className="play_batting_inr">
                              <div
                                className="inr-plus"
                                onClick={CashDecriment2}
                              >
                                -
                              </div>

                              <div className="tag-bet-input">
                                <input
                                  className="tag-bet-input-div"
                                  onChange={(e) =>
                                    verify2Amount(e.target.value)
                                  }
                                  value={cash2Plus}
                                  maxLength="5"
                                />
                                <div className="tag-bet-input-price">
                                  <div className="px-1">{cash2Plus}</div>
                                  <div>INR</div>
                                </div>
                              </div>

                              <div
                                className="inr-minus"
                                onClick={CashIncreament2}
                              >
                                +
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="play-batting-btns d-flex justify-content-between">
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCash2Plus(parseInt(cash2Plus) + 150)
                            }
                          >
                            +150
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCash2Plus(parseInt(cash2Plus) + 200)
                            }
                          >
                            +200
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCash2Plus(parseInt(cash2Plus) + 250)
                            }
                          >
                            +250
                          </button>
                          <button
                            className="main-btn play_batting-btn"
                            onClick={() =>
                              setCash2Plus(parseInt(cash2Plus) + 300)
                            }
                          >
                            +300
                          </button>
                        </div>
                      </div>
                      <div className="play_batting d-flex justify-content-between">
                        <div className="play_batting-checkbox d-flex display-column gy-2">
                          <p>Auto</p>
                          <label
                            className="form-label lms_checkbox_1"
                            htmlFor="auto_3"
                          >
                            <input
                              type="checkbox"
                              id="auto_3"
                              onChange={(e) =>
                                setAutoBet2Second(e.target.checked)
                              }
                            />
                            <div className="slider-check round"></div>
                          </label>
                        </div>
                        <div className="play_batting_count">
                          <p>Collect</p>
                          <div className="play_batting_inr">
                            <div
                              className="inr-plus"
                              onClick={PresentDecriment2}
                            >
                              -
                            </div>

                            <div className="tag-bet-input">
                              <input
                                className="tag-bet-input-div"
                                maxLength="4"
                                value={present2Plus}
                                onChange={(e) =>
                                  cashoutValue2Check(e.target.value)
                                }
                                disabled
                              />
                              <div
                                className="tag-bet-input-price"
                                style={{ left: "-8px" }}
                              >
                                <div className="px-1">{present2Plus}</div>
                                <div>x</div>
                              </div>
                            </div>
                            <div
                              className="inr-minus"
                              onClick={PresentIncreament2}
                            >
                              +
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bet_content-btn">
                      {betBtn2Visibility === true ? (
                        <>
                          {model2Show === true ? (
                            <button
                              className="main-btn bet-btn"
                              data-bs-toggle="modal"
                              data-bs-target="##AmountDeposite"
                            >
                              BET
                            </button>
                          ) : (
                            <button
                              className="main-btn bet-btn"
                              onClick={betOn2Game}
                            >
                              BET
                            </button>
                          )}
                        </>
                      ) : cancelBtn2Visibility === true ? (
                        <button
                          className="main-btn bet-btn bet_cancel"
                          onClick={cancelbetOn2Game}
                        >
                          Cancel
                        </button>
                      ) : cashoutBtn2Visibility === true ? (
                        <button
                          className="bet_checkout"
                          onClick={cashoutbetOn2Game}
                        >
                          {(cash2Plus * count).toFixed(2)}x CASHOUT
                        </button>
                      ) : (
                        ""
                      )}
                      {/* // {cancelBtn2Visibility === true ? (
                      //   <button
                      //     className="main-btn bet-btn bet_cancel"
                      //     onClick={cancelbetOn2Game}
                      //   >
                      //     Cancel
                      //   </button>
                      // ) : (
                      //   ""
                      // )} */}
                      {/* {cashoutBtn2Visibility === true ? (
                        <button
                          className="bet_checkout"
                          onClick={cashoutbetOn2Game}
                        >
                          {(cash2Plus * count).toFixed(2)}x CASHOUT
                        </button>
                      ) : (
                        ""
                      )} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSide;
