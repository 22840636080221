import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";

const TableShow = ({ data }) => {
  return (
    <>
      {data.length === 0 ? (
        <div className="text-center" style={{ width: "100%" }}>
          <p>No Data Found</p>
        </div>
      ) : (
        <TableContainer component={Paper} className="table">
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="tableCell">User ID</TableCell>
                <TableCell className="tableCell">Date</TableCell>
                <TableCell className="tableCell">Amount</TableCell>
                <TableCell className="tableCell">Status</TableCell>
                <TableCell className="tableCell">Type Method</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((data) => (
                  <TableRow key={data._id}>
                    <TableCell className="tableCell">{data._id}</TableCell>
                    <TableCell className="tableCell">
                      {moment(data.createdAt).format("DD MMM YY")}
                    </TableCell>
                    <TableCell className="tableCell">{data.amount}</TableCell>
                    <TableCell className="tableCell">
                      <span className={`status ${data.status}`}>
                        {data.status}
                      </span>
                    </TableCell>
                    <TableCell className="tableCell">
                      <span
                        style={{ color: `${data.mobile ? "green" : "red"}` }}
                      >
                        {data.mobile ? "Deposit" : "Withdraw"}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default TableShow;
