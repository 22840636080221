import React, { useEffect, useState } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import Axios from "../redux/axios";

const Chart = () => {
  const [todayGame, setTodayGame] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `${localStorage.getItem("adminToken")}`,
      },
    };
    Axios.get("/show-revenue", config)
      .then((res) => {
        setTodayGame(res.data.Profit);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <div className="chart">
        <div className="title">Last 6 Months (Revenue)</div>
        <ResponsiveContainer width="100%" aspect={2.0}>
          <AreaChart
            width={730}
            height={250}
            data={todayGame}
            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="name" stroke="gray" />
            <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="Total"
              stroke="#8884d8"
              fillOpacity={1}
              fill="url(#total)"
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export default Chart;
