import React, { useState } from "react";
import ChangeLanguage from "../ChangeLanguage/ChangeLanguage";
import PaymentHistory from "../PaymentHistory/PaymentHistory";
import Support from "../Support/Support";
import TermsAndCondition from "../TermsAndCondition/TermsAndCondition";
import Withdraw from "../Withdraw/Withdraw";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ImageDrop from "./ImageDrop";
import HowToPlay from "../HowToPlay/HowToPlay";
import ReferAndEarn from "../ReferAndEarn/ReferAndEarn";

const InfoPop = ({ infoPop }) => {
  const navigate = useNavigate();

  const logOutLogin = () => {
    localStorage.clear();
    toast.success("Logout Successfully");
    setTimeout(() => {
      window.location.reload();
    }, 1500);
    navigate("/");
  };

  // const jetSound = new Audio("./sounds/jetsound.mp3");
  // const soundHandler = (e) => {
  //   if (e.target.checked) {
  //     jetSound.play();
  //   } else {
  //     jetSound.pause();
  //   }
  // };

  const userData = JSON.parse(localStorage.getItem("user-data"));

  const userDetails = JSON.parse(localStorage.getItem("user-details"));

  const data = userDetails && userDetails.name;

  const localStorageClear = () => {
    localStorage.removeItem("deposite-amount");
  };

  return (
    <>
      <div className={`user-detail-popup ${infoPop === true ? "active" : ""}`}>
        <div className="accordion user-detail-content" id="accordionExample">
          <div className="user_title">
            <ImageDrop />
            <h3 className="user_name">
              {userDetails ? data.slice(0, 6) : userData && userData.name}
            </h3>
          </div>
          <div className="user_list">
            <div className="user_list_item">
              <div className="accordion-item">
                <div className="accordion-header" id="headingOne">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    <img src="./images/wollet_account.png" />
                    <p>Wallet Acount</p>
                  </div>
                </div>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#AmountDeposite"
                      onClick={localStorageClear}
                    >
                      <img src="./images/deposit_wallet.png" />
                      <p className="closeP">deposit</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal2"
                    >
                      <img src="./images/withdraw.png" />
                      <p className="closeP">Withdraw</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal3"
                    >
                      <img src="./images/payment_history.png" />
                      <p className="closeP">Payment History</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="user_list_item"
              data-bs-toggle="modal"
              data-bs-target="#ReferAndEarn"
            >
              <img src="./images/help_information.png" />
              <p className="closeP">Refer And Earn</p>
            </div>
            <div
              className="user_list_item"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal5"
            >
              <img src="./images/help_information.png" />
              <p className="closeP">How to Play</p>
            </div>
            {/* <div className="user_list_item">
              <img src="./images/mybets.png"  />
              <p>My bets</p>
            </div> */}
            {/* <div className="user_list_item">
              <img src="./images/notification.png"  />
              <p>Notification</p>
              <label
                className="form-label lms_checkbox_1"
                htmlFor="personalInformation"
              >
                <input type="checkbox" id="personalInformation" />
                <div className="slider-check round"></div>
              </label>
            </div> */}
            {/* <div className="user_list_item">
              <img src="./images/sound.png"  />
              <p>Sound</p>
              <label className="form-label lms_checkbox_1" htmlFor="Sound">
                <input
                  type="checkbox"
                  id="Sound"
                  // onChange={soundHandler}
                />
                <div className="slider-check round"></div>
              </label>
            </div> */}
            {/* <div
              className="user_list_item"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal4"
            >
              <img src="./images/change_langauge.png"  />
              <p className="closeP">Change Language</p>
            </div> */}

            <div className="user_list_item">
              <div className="accordion-item">
                <div className="accordion-header" id="user_list_item2">
                  <div
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapes_user_list_item2"
                    aria-expanded="false"
                    aria-controls="collapes_user_list_item2"
                  >
                    <img src="./images/personal_information.png" />
                    <p>Help and Information</p>
                  </div>
                </div>
                <div
                  id="collapes_user_list_item2"
                  className="accordion-collapse collapse"
                  aria-labelledby="user_list_item2"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#Support"
                    >
                      <img src="./images/support.png" />
                      <p className="closeP">Support</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#TermCondition"
                    >
                      <img src="./images/term_and_conditin.png" />
                      <p className="closeP">Term &amp; condition</p>
                    </div>
                    <div
                      className="user_list_item"
                      data-bs-toggle="modal"
                      data-bs-target="#TermCondition"
                    >
                      <img src="./images/privacy_policy.png" />
                      <p className="closeP">Privacy Policy</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="user_list_item" onClick={logOutLogin}>
              <img src="./images/logout.png" />
              <p>logout</p>
            </div>
          </div>
        </div>
      </div>
      {/* <PersonalInfo /> */}
      <Withdraw />
      <PaymentHistory />
      <ChangeLanguage />
      <Support />
      <TermsAndCondition />
      <HowToPlay />
      <ReferAndEarn />
    </>
  );
};

export default InfoPop;
