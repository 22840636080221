import React, { useEffect, useState } from "react";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import { Helmet } from "react-helmet";
import Loader from "../Loader/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "../Pagination";

const Games = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const [totalLength, setTotalLength] = useState("");

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  useEffect(() => {
    setLoader(true);
    Axios.get(`/all-games?page=1`, config)
      .then((res) => {
        setUserData(res.data.message);
        setLoader(false);
        setTotalLength(Math.ceil(res.data.length / 12));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const foundDataOnChange = async (currentPage) => {
    setLoader1(true);
    const res = await Axios.get(
      `/all-games?page=${currentPage}&limit=10`,
      config
    );
    if (res.data.status === true) {
      setLoader1(false);
    }
    const data = res.data.message;
    return data;
  };

  const handlePageChange = async (data) => {
    let currentPage = data.selected + 1;
    const showData = await foundDataOnChange(currentPage);
    setUserData(showData);
  };

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              {loader === true ? (
                <Loader />
              ) : (
                <div className="datatable">
                  <div className="datatableTitle">All Games</div>
                  {loader1 ? (
                    <Loader />
                  ) : (
                    <TableContainer component={Paper} className="table">
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell className="tableCell">Game Id</TableCell>
                            <TableCell className="tableCell">number</TableCell>
                            <TableCell className="tableCell">
                              Total Bet Amount
                            </TableCell>
                            <TableCell className="tableCell">
                              Total Bets
                            </TableCell>
                            <TableCell className="tableCell">Profit</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userData &&
                            userData.map((data) => (
                              <TableRow key={data._id}>
                                <TableCell className="tableCell">
                                  {data._id}
                                </TableCell>
                                <TableCell className="tableCell">
                                  {data.random_number}
                                </TableCell>
                                <TableCell className="tableCell">
                                  <span>{data.total_value}</span>
                                </TableCell>
                                <TableCell className="tableCell">
                                  <span>{data.bet_value}</span>
                                </TableCell>
                                <TableCell className="tableCell">
                                  <span>{data.profit}</span>
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                  <div className="pagination-color-change pb-3">
                    <Pagination
                      handlePageChange={handlePageChange}
                      totalLength={totalLength}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Games;
