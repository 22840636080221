import React, { useEffect, useState } from "react";
import "./AdminHome.css";
import Process from "./Process";
import AdminHeader from "./common/AdminHeader";
import SideBar from "./SideBar";
import Status from "./Status";
import { Helmet } from "react-helmet";
import TableShow from "./Table/TableShow";
import Axios from "../redux/axios";

const AdminHome = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const config = {
      headers: {
        Authorization: `${localStorage.getItem("adminToken")}`,
      },
    };
    Axios.get("/all-payment-data", config).then((res) => {
      setData(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change">
        <div className="col-lg-12" data-aos={"fade-up"}>
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10" data-aos={"fade-left"}>
              <div className="widgets">
                <Status />
              </div>
              <Process />
              <div className="mt-4 mx-4">
                <TableShow data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHome;
