import Sidebar from "../SideBar";
import Chart from "../Chart";
import AdminHeader from "../common/AdminHeader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../redux/axios";
import { Helmet } from "react-helmet";
import DummyImage from "./user_dp_camera (1).png";
import Loader from "../Loader/Loader";
import TableShow from "../Table/TableShow";

const Single = () => {
  const { name } = useParams();
  const [userData, setUserData] = useState([]);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };
  useEffect(() => {
    setLoader(true);
    Axios.get("/get-all-users", config)
      .then((res) => {
        const dataFind = res.data.users.find((data) => data.name === name);
        setUserData(dataFind);
        setLoader(false);
        const data = {
          _id: dataFind._id,
        };
        Axios.post("/find-payment-data", data, config)
          .then((res) => {
            setData(res.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {});
  }, []);

  const base64String = btoa(
    String.fromCharCode(
      ...new Uint8Array(
        userData &&
          userData.image &&
          userData.image.data &&
          userData.image.data.data
      )
    )
  );

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <Sidebar />
            </div>
            {loader === true ? (
              <Loader />
            ) : (
              <div className="col-lg-10">
                <div className="user-information">
                  <div className="left">
                    <h1 className="title">Information</h1>
                    <div className="item">
                      {userData.image === undefined ? (
                        <img src={DummyImage} className="itemImg" />
                      ) : (
                        <img
                          src={`data:image/png;base64,${base64String}`}
                          className="itemImg"
                        />
                      )}
                      <div className="details">
                        <h1 className="itemTitle">
                          {userData && userData.name}
                        </h1>
                        <div className="detailItem">
                          <span className="itemKey">Email:</span>
                          <span className="itemValue">
                            {userData && userData.email}
                          </span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Phone:</span>
                          <span className="itemValue">
                            {userData && userData.mobile}
                          </span>
                        </div>

                        <div className="detailItem">
                          <span className="itemKey">City:</span>
                          <span className="itemValue">
                            {userData && userData.city}
                          </span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Country:</span>
                          <span className="itemValue">
                            {userData && userData.country}
                          </span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Chips:</span>
                          <span className="itemValue">
                            {userData && userData.balance}
                          </span>
                        </div>
                        <div className="detailItem">
                          <span className="itemKey">Refer Code:</span>
                          <span className="itemValue">
                            {userData && userData.referralCode}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right">
                    <Chart
                      aspect={3 / 1}
                      title="User Spending ( Last 6 Months)"
                    />
                  </div>
                </div>
                <div className="">{/* <List userData={userData} /> */}</div>
                <div className="mt-4 mx-4">
                  <TableShow data={data} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Single;
