import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import Loader from "../Loader/Loader";

const DepositeDetails = () => {
  const { id } = useParams();
  const [userData, setUserData] = useState([]);
  const [updateAmount, setUpdateAmount] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };
  useEffect(() => {
    setLoader(true);
    Axios.get("/get-deposite-data", config)
      .then((res) => {
        setUserData(res.data.deposite.find((data) => data._id === id));
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  const depositeApproaval = (e) => {
    const data = {
      data: e,
    };
    Axios.post(`/update-deposite`, data, config)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  // const base64String = btoa(
  //   String.fromCharCode(
  //     ...new Uint8Array(
  //       userData &&
  //         userData.paymentPhoto &&
  //         userData.paymentPhoto.data &&
  //         userData.paymentPhoto.data.data
  //     )
  //   )
  // );

  const submitDepositeAmount = (e) => {
    e.preventDefault();
    setShow(false);
  };

  return (
    <>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              {loader === true ? (
                <Loader />
              ) : (
                <div className="withdraw-space left">
                  <h1 className="title-withdraw">Deposit Information</h1>
                  <div className="item deposite-view-flex">
                    <div className="details">
                      <div className="detailItem">
                        <span className="itemKey">Deposit Amount : </span>
                        <span className="itemValue">
                          {updateAmount
                            ? updateAmount
                            : userData && userData.amount}
                        </span>

                        {userData && userData.status === "pending" ? (
                          <span
                            className="editAmount"
                            onClick={() => setShow(!show)}
                          >
                            Edit<i className="fa fa-pencil"></i>
                          </span>
                        ) : userData &&
                          userData.status === "accept" ? null : userData &&
                          userData.status === "reject" ? null : null}
                      </div>
                      {show === true ? (
                        <div className="detailItem">
                          <form onSubmit={submitDepositeAmount}>
                            <input
                              type="text"
                              placeholder="Enter Amount"
                              value={updateAmount}
                              onChange={(e) => setUpdateAmount(e.target.value)}
                            />
                            <button type="submit">Submit</button>
                          </form>
                        </div>
                      ) : null}
                      {userData && userData.UtrNumber ? (
                        <div className="detailItem">
                          <span className="itemKey">UTR Number : </span>
                          <span className="itemValue">
                            {userData && userData.UtrNumber}
                          </span>
                        </div>
                      ) : (
                        <div className="detailItem">
                          <span className="itemKey">Transcation Id : </span>
                          <span className="itemValue">
                            {userData && userData.transcationId}
                          </span>
                        </div>
                      )}
                      {userData && userData.BankHolderName ? (
                        <div className="detailItem">
                          <span className="itemKey">Bank Account Name : </span>
                          <span className="itemValue">
                            {userData && userData.BankHolderName}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <div className="detailItem">
                      <span className="itemKey">UPI Id : </span>
                      <span className="itemValue">{userData.upiId}</span>
                    </div> */}
                      {userData && userData.BankAccountNumber ? (
                        <div className="detailItem">
                          <span className="itemKey">
                            Bank Account Number :{" "}
                          </span>
                          <span className="itemValue">
                            {userData && userData.BankAccountNumber}
                          </span>
                        </div>
                      ) : (
                        <div className="detailItem">
                          <span className="itemKey">Mobile Number : </span>
                          <span className="itemValue">
                            {userData && userData.mobile}
                          </span>
                        </div>
                      )}
                      <div className="detailItem  d-flex align-items-center">
                        <span className="itemKey">Status : </span>
                        <span className="itemValue">
                          {userData && userData.status === "pending" ? (
                            <div className="status pending">
                              {userData && userData.status}
                            </div>
                          ) : userData && userData.status === "accept" ? (
                            <div className="status accept">
                              {userData && userData.status}
                            </div>
                          ) : userData && userData.status === "reject" ? (
                            <div className="status reject">
                              {userData && userData.status}
                            </div>
                          ) : null}
                        </span>
                      </div>
                      <div className="detailItem d-flex align-items-center">
                        <span className="itemKey">Action : </span>
                        {userData && userData.status === "pending" ? (
                          <div className="cellAction">
                            <div
                              className="viewButton1"
                              onClick={() =>
                                depositeApproaval({
                                  type: "accept",
                                  _id: userData && userData._id,
                                  amount: updateAmount
                                    ? updateAmount
                                    : userData && userData.amount,
                                  userId: userData && userData.Userid,
                                  UserBonosId: userData && userData.UserBonosId,
                                  uniqueReferralId:
                                    userData && userData.uniqueReferralId,
                                })
                              }
                            >
                              success
                            </div>
                            <div
                              className="deleteButton"
                              onClick={() =>
                                depositeApproaval({
                                  type: "reject",
                                  _id: userData && userData._id,
                                  amount: 0,
                                  userId: userData && userData.Userid,
                                })
                              }
                            >
                              reject
                            </div>
                          </div>
                        ) : userData && userData.status === "accept" ? (
                          <div className="cellAction">
                            <div className="viewButton1">successfully</div>
                          </div>
                        ) : userData && userData.status === "reject" ? (
                          <div className="cellAction">
                            <div className="deleteButton">rejected</div>
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="deposite-view-flex-img">
                      {/* <img
                        className="cellImg"
                        src={`data:image/png;base64,${base64String}`}
                      /> */}
                      <img
                        className="cellImg"
                        src={userData && userData.paymentPhoto}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositeDetails;
