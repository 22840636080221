import React, { useState } from "react";
import { toast } from "react-toastify";

const DepositeAmount = () => {
  const [value, setValue] = useState("");

  const submitAmountValue = () => {
    if (!value) {
      toast.error("please enter amount");
    } else {
      localStorage.setItem("deposite-amount", value);
    }
  };

  return (
    <>
      <div
        className="modal review-model show"
        id="AmountDeposite"
        aria-hidden="false"
        role="dialog"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <img
              src="./images/model_left.png"
              className="img-fluid model_left_img"
            />
            <div className="modal-body">
              <div className="change_langauge_content">
                <div className="deposit-left">
                  <div className="deposit-left-top">
                    <img
                      src="./images/model_close.png"
                      data-bs-dismiss="modal"
                      className="model_close"
                    />
                    <h1>Deposite</h1>
                  </div>
                </div>
                <div className="refer_section">
                  <div className="form-group floating">
                    <input
                      type="number"
                      className="form-control floating"
                      id="usr"
                      name="number"
                      value={value}
                      onChange={(e) => setValue(e.target.value)}
                      required
                    />
                    <label htmlFor="usr">
                      Please enter the amount you want to deposit
                    </label>
                    <p className="show-rupey-icon">
                      <i className="fa fa-rupee"></i>
                    </p>
                  </div>
                  <div className="amount-data-show">
                    <button
                      className="amount-data-show-btn"
                      onClick={() => setValue(300)}
                    >
                      <div className="amount-data-show-amount">+300</div>
                    </button>
                    <button
                      className="amount-data-show-btn"
                      onClick={() => setValue(600)}
                    >
                      <div className="amount-data-show-amount">+600</div>
                    </button>
                    <button
                      className="amount-data-show-btn"
                      onClick={() => setValue(900)}
                    >
                      <div className="amount-data-show-amount">+900</div>
                    </button>
                    <button
                      className="amount-data-show-btn"
                      onClick={() => setValue(1000)}
                    >
                      <div className="amount-data-show-amount">+1000</div>
                    </button>
                  </div>
                  <div className="deposite-amount">
                    {value && (
                      <button
                        className="deposite-amount-btn"
                        onClick={submitAmountValue}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                        data-bs-dismiss="modal"
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositeAmount;
