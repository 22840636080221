import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import Axios from "../../redux/axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from "../Loader/Loader";

const Deposite = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  useEffect(() => {
    setLoader(true);
    Axios.get(`/get-deposite-data`, config)
      .then((res) => {
        setUserData(res.data.deposite);
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  const columns = useMemo(() => [
    { field: "amount", headerName: "Amount", width: 100 },
    { field: "UtrNumber", headerName: "UTR Number", width: 150 },
    {
      field: "transcationId",
      headerName: "Transcation Id",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            className="cellWithImg"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            {params.row.transcationId}
          </div>
        );
      },
    },
    { field: "mobile", headerName: "Mobile", width: 150 },
    { field: "BankHolderName", headerName: "Bank Holder Name", width: 150 },
    { field: "_id", hide: true },
    // { field: "upiId", headerName: "UPI ID", width: 200 },
  ]);

  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  const statusShowColumn = [
    {
      field: "status",
      headerName: "status",
      width: 100,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "pending" ? (
              <div className="status pending">{params.row.status}</div>
            ) : params.row.status === "accept" ? (
              <div className="status accept">{params.row.status}</div>
            ) : params.row.status === "reject" ? (
              <div className="status reject">{params.row.status}</div>
            ) : null}
          </>
        );
      },
    },
  ];

  const actionColumn = [
    {
      field: "action",
      headerName: "view",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <Link to={`/deposite-details/${params.row._id}`}>
              <div className="view-page-details">View</div>
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              {loader === true ? (
                <Loader />
              ) : (
                <div className="datatable">
                  <div className="datatableTitle">Deposit Details</div>
                  <DataGrid
                    className="datagrid"
                    columns={columns.concat(statusShowColumn, actionColumn)}
                    rows={rows}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content model-image-payment">
              <div className="modal-body">
                <img src={userData && userData.paymentPhoto} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposite;
