import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar";

const Register = ({
  openGooglePopUp,
  openFacebookPopUp,
  inputChangeHandler,
  value,
}) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  var numberRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const clickRegisterHandler = () => {
    if (!value.email) {
      toast.error("Enter Email Address");
    } else if (!regex.test(value.email)) {
      toast.error("Invalid Email Address");
    } else if (!value.phoneNumber) {
      toast.error("Enter a Phone Number");
    } else if (!numberRegex.test(value.phoneNumber)) {
      toast.error("Invalid phone number");
    } else if (!value.password) {
      toast.error("Enter Password");
    } else if (value.password.length < 8) {
      toast.error("The password must be at least 8 characters");
    } else if (!strongRegex.test(value.password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else {
      navigate("/personalinformation");
    }
  };

  return (
    <div>
      <Navbar />
      <section id="LoginRegister" data-aos={"fade-right"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Login to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/register_right.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" />
                    </Link>
                  </div>
                  <img
                    src="./images/register_right_bottom.png"
                    className="register_right_bottom"
                  />
                  <img
                    src="./images/register_right_top.png"
                    className="register_right_top"
                  />
                  <div className="login_informention Register position-relative">
                    <img
                      src="./images/register_bg.png"
                      className="login_bg_IMG"
                    />
                    <div className="login_content login_content2">
                      <h4>Username Password</h4>
                      <div className="Login_line">
                        <div className="line_dots">
                          <div className="line_dot complate"></div>
                          <div className="line_dot"></div>
                          <div className="line_dot"></div>
                          <div className="line_dot"></div>
                        </div>
                      </div>
                      <div className="form-section row">
                        <div className="col-12">
                          <div className="form-group floating">
                            <input
                              type="text"
                              name="phoneNumber"
                              className="form-control floating"
                              id="PhoneNumber"
                              autoComplete="new-password"
                              value={value.phoneNumber}
                              onChange={(e) => inputChangeHandler(e)}
                              required
                            />
                            <label htmlFor="PhoneNumber">Phone Number</label>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group floating">
                            <input
                              type="email"
                              className="form-control floating"
                              name="email"
                              value={value.email}
                              autoComplete="new-password"
                              id="usr"
                              onChange={(e) => inputChangeHandler(e)}
                              required
                            />
                            <label htmlFor="usr">Email address</label>
                          </div>
                        </div>
                        {/* <div className="col-4">
                          <div className="form-group floating">
                            <input
                              type="text"
                              name="countryCode"
                              className="form-control floating"
                              value={value.countryCode}
                              id="countryCode"
                              onChange={(e) => inputChangeHandler(e)}
                              required
                              placeholder="+91 India"
                            />
                            <label htmlFor="countryCode">Country Code</label>
                          </div>
                        </div> */}

                        <div className="col-12">
                          <div className="form-group floating">
                            <input
                              type={showPassword ? "text" : "password"}
                              name="password"
                              className="form-control floating"
                              id="usrpassword"
                              autoComplete="new-password"
                              value={value.password}
                              onChange={(e) => inputChangeHandler(e)}
                              required
                            />
                            <label htmlFor="usr">Password</label>
                            {showPassword ? (
                              <img
                                src="./images/show_password.png"
                                id="showPassword"
                                onClick={() => setShowPassword(false)}
                              />
                            ) : (
                              <img
                                src="./images/hide_password.png"
                                id="showPassword"
                                onClick={() => setShowPassword(true)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <button
                            className="main-btn login-btn next-page"
                            onClick={clickRegisterHandler}
                          >
                            <img src="./images/next_page_vector.png" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="login_option login_option2">
                      <div className="login_option_content">
                        <h5>OR</h5>
                        <h5>Login with Social Account</h5>
                        <div className="support_btns">
                          <button
                            className="main-btn google_btn"
                            onClick={openGooglePopUp}
                          >
                            <img src="./images/google_vector.png" />
                            <h5>Login With Google</h5>
                          </button>
                          <button
                            className="main-btn facebook_btn"
                            onClick={openFacebookPopUp}
                          >
                            <img src="./images/facebook_vector.png" />
                            <h5>facebook</h5>
                          </button>
                        </div>
                        <h5 className="text-center">
                          Don't have an Account ?
                          <span className="text-yellow">
                            <Link to="/login">Login</Link>
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Register;
