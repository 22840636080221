import { Button } from "@mui/material";
import React from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import { toast } from "react-toastify";

const SubAdmin = () => {
  const [role, setRole] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  var strongRegex =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+])[0-9a-zA-Z!@#$%^&*()_+]{8,}$/;

  const Style = {
    labelStyle: {
      color: "#000",
      fontSize: 18,
      fontWeight: "800",
      margin: 10,
    },
    inputTag: {
      border: "1 solid #000",
      color: "#000",
      fontWeight: "500",
      fontSize: 14,
      borderColor: "#000",
      borderStyle: "solid",
      borderWidth: 1,
      padding: 10,
      borderRadius: 20,
    },
  };

  const onChangeValue = (e) => {
    let updateList = [...role];
    if (e.target.checked) {
      updateList = [...role, e.target.value];
    } else {
      updateList.splice(role.indexOf(e.target.value), 1);
    }
    setRole(updateList);
  };

  const subAdminCreate = (e) => {
    e.preventDefault();
    if (!email) {
      toast.error("Please enter email");
    } else if (!regex.test(email)) {
      toast.error("Enter a Correct Email Address");
    } else if (!password) {
      toast.error("Please enter password");
    } else if (password.length < 8) {
      toast.error("The password must be at least 8 characters");
    } else if (!strongRegex.test(password)) {
      toast.error(
        "password should contain numeric value, special character, upper case letter and lower case letter"
      );
    } else {
      const data = {
        email: email,
        password: password,
        Role: role,
      };
      Axios.post("/admin-register", data)
        .then((res) => {
          toast.success(res.data.message);
          window.location.reload();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Sub Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="datatable mt-3">
                <div className="datatableTitle">Sub Admin</div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="sub-admin-flex">
                      <label htmlFor="" style={Style.labelStyle}>
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Enter email"
                        style={Style.inputTag}
                        autoComplete="new-password"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="sub-admin-flex">
                      <label htmlFor="" style={Style.labelStyle}>
                        Password
                      </label>
                      <input
                        type="password"
                        placeholder="Enter password"
                        style={Style.inputTag}
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="" style={Style.labelStyle}>
                      Add Role
                    </label>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="users"
                        type="checkbox"
                        onChange={(e) => onChangeValue(e)}
                        value="users"
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="users"
                        title="Click for select/unselect"
                      >
                        Users
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="games"
                        type="checkbox"
                        value="games"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="games"
                        title="Click for select/unselect"
                      >
                        Games
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="payment"
                        type="checkbox"
                        value="payment"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="payment"
                        title="Click for select/unselect"
                      >
                        Payment Number
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="gamePlay"
                        type="checkbox"
                        value="gamePlay"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="gamePlay"
                        title="Click for select/unselect"
                      >
                        Game Play
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="withdraw"
                        type="checkbox"
                        value="withdraw"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="withdraw"
                        title="Click for select/unselect"
                      >
                        Withdraw
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="BotsData"
                        type="checkbox"
                        value="botsData"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="BotsData"
                        title="Click for select/unselect"
                      >
                        Bots Data
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="deposite"
                        type="checkbox"
                        value="deposite"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="deposite"
                        title="Click for select/unselect"
                      >
                        Deposit
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="support"
                        type="checkbox"
                        value="support"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="support"
                        title="Click for select/unselect"
                      >
                        Support
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="subAdminList"
                        type="checkbox"
                        value="subAdminList"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="subAdminList"
                        title="Click for select/unselect"
                      >
                        Sub Admin List
                      </label>
                    </div>
                    <div className="">
                      <input
                        className="cl-custom-check"
                        id="subAdmin"
                        type="checkbox"
                        value="subAdmin"
                        onChange={(e) => onChangeValue(e)}
                      />
                      <label
                        className="cl-custom-check-label"
                        htmlFor="subAdmin"
                        title="Click for select/unselect"
                      >
                        Sub Admin
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6 mt-5">
                    {role &&
                      role.map((data, i) => (
                        <button
                          type="button"
                          className="btn btn-outline-primary mx-2 mb-2"
                          key={i}
                        >
                          {data}
                        </button>
                      ))}
                  </div>
                  <div className="col-md-12 mt-4">
                    <Button
                      variant="contained"
                      color="success"
                      type="submit"
                      onClick={subAdminCreate}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubAdmin;
