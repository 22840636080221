import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";

const PaymentNumber = () => {
  const [number, setNumber] = useState("");
  const [data, setData] = useState("");
  const [payTmQr, setPayTmQr] = useState("");
  const [phonePe, setPhonePe] = useState("");
  const [googlePay, setGooglePay] = useState("");
  const [upiQR, setUpiQR] = useState("");
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  const inputHandlerEvent = (e) => {
    setNumber({ ...number, [e.target.name]: e.target.value });
  };

  const paymentAddNumber = (find) => {
    if (find === "paytm") {
      if (!number.paytm) {
        toast.error("please enter a payTm number");
      } else if (!payTmQr) {
        toast.error("please enter a PayTm QR Code");
      } else {
        const formData = new FormData();
        formData.append("payTmNumber", number.paytm);
        formData.append("paytmQr", payTmQr);
        formData.append("paymentMethod", find);

        Axios.post("/payment-number", formData, config)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (find === "phonepay") {
      if (!number.phonePe) {
        toast.error("please enter a phonePay number");
      } else if (!phonePe) {
        toast.error("please enter a Phone Pay QR Code");
      } else {
        const formData = new FormData();
        formData.append("phonePeNumber", number.phonePe);
        formData.append("phonePayQr", phonePe);
        formData.append("paymentMethod", find);

        Axios.post("/payment-number", formData, config)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

    if (find === "upi") {
      if (!number.upi) {
        toast.error("please enter a UPI number");
      } else if (!upiQR) {
        toast.error("please enter a Upi QR Code");
      } else {
        const formData = new FormData();
        formData.append("upiNumber", number.upi);
        formData.append("upiQr", upiQR);
        formData.append("paymentMethod", find);

        Axios.post("/payment-number", formData, config)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (find === "googlepay") {
      if (!number.googlePay) {
        toast.error("please enter a Google Pay number");
      } else if (!googlePay) {
        toast.error("please enter a Google Pay QR Code");
      } else {
        const formData = new FormData();
        formData.append("googlePayNumber", number.googlePay);
        formData.append("googlePayQr", googlePay);
        formData.append("paymentMethod", find);

        Axios.post("/payment-number", formData, config)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    if (find === "bank") {
      if (!number.bankName) {
        toast.error("please enter a Bank Name");
      } else if (!number.bankHolder) {
        toast.error("please enter a Bank Account Holder Name");
      } else if (!number.ifsc) {
        toast.error("please enter a Bank Account IFSC Code");
      } else if (!number.accountNumber) {
        toast.error("please enter a Bank Account Number");
      } else {
        const formData = new FormData();
        formData.append("bankName", number.bankName);
        formData.append("holderName", number.bankHolder);
        formData.append("ifscCode", number.ifsc);
        formData.append("accountNumber", number.accountNumber);
        formData.append("paymentMethod", find);

        Axios.post("/payment-number", formData, config)
          .then((res) => {
            if (res.data.status === true) {
              toast.success(res.data.message);
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  useEffect(() => {
    Axios.get("/payment-number", config)
      .then((res) => {
        setData(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="datatable">
                <div className="datatableTitle">Payment Number</div>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a PayTm Number"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="paytm"
                      value={number.paytm}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="Qr-input-add">
                      <p>PayTm Upload QR Code Image</p>
                      <input
                        type="file"
                        onChange={(e) => setPayTmQr(e.target.files[0])}
                        name="paytmQr"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={() => paymentAddNumber("paytm")}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a PhonePe number"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="phonePe"
                      value={number.phonePe}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="Qr-input-add">
                      <p>Phone Pay Upload QR Code Image</p>
                      <input
                        type="file"
                        onChange={(e) => setPhonePe(e.target.files[0])}
                        name="phonePayQr"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={() => paymentAddNumber("phonepay")}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a UPI number"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="upi"
                      value={number.upi}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="Qr-input-add">
                      <p>Upi Upload QR Code Image</p>
                      <input
                        type="file"
                        onChange={(e) => setUpiQR(e.target.files[0])}
                        name="upiQr"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={() => paymentAddNumber("upi")}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a Google Pay number"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="googlePay"
                      value={number.googlePay}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="Qr-input-add">
                      <p>Google Pay Upload QR Code Image</p>
                      <input
                        type="file"
                        onChange={(e) => setGooglePay(e.target.files[0])}
                        name="googlePayQr"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={() => paymentAddNumber("googlepay")}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a Bank Name"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="bankName"
                      value={number.bankName}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a Bank Account Holder Name"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="bankHolder"
                      value={number.bankHolder}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a IFSC Code"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="ifsc"
                      value={number.ifsc}
                      className="payment-input-change"
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <input
                      type="text"
                      placeholder="please enter a Account Number"
                      onChange={(e) => inputHandlerEvent(e)}
                      name="accountNumber"
                      value={number.accountNumber}
                      className="payment-input-change"
                    />
                  </div>

                  <div className="col-md-12">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={() => paymentAddNumber("bank")}
                    >
                      Submit
                    </button>
                  </div>
                </div>

                <div className="mt-3">
                  <p className="mt-2">
                    Your PayTm Number -{" "}
                    {data && data.payTm && data.payTm.payTmNumber}
                  </p>
                  <p className="mt-2">
                    Your Phone Pe Number -{" "}
                    {data && data.phonePay && data.phonePay.phonePeNumber}
                  </p>
                  <p className="mt-2">
                    Your UPI Number - {data && data.Upi && data.Upi.upiNumber}
                  </p>
                  <p className="mt-2">
                    Your GooglePay Number -
                    {data && data.googlePay && data.googlePay.googlePayNumber}
                  </p>
                  <p className="mt-2">
                    Your Bank Name - {data && data.Bank && data.Bank.bankName}
                  </p>
                  <p className="mt-2">
                    Your Bank Account Holder Name -{" "}
                    {data && data.Bank && data.Bank.holderName}
                  </p>
                  <p className="mt-2">
                    Your IFSC Code - {data && data.Bank && data.Bank.ifscCode}
                  </p>
                  <p className="mt-2">
                    Your Bank Account Number -{" "}
                    {data && data.Bank && data.Bank.accountNumber}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentNumber;
