import React, { useEffect } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import GamesIcon from "@mui/icons-material/Games";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import PaidIcon from "@mui/icons-material/Paid";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import { Link } from "react-router-dom";
import Axios from "../redux/axios";
import { useState } from "react";

const SideBar = () => {
  const [role, setRole] = useState("");
  const [showRole, setShowRole] = useState("");

  const findAdmin = JSON.parse(localStorage.getItem("adminDetils"));

  const adminLogOut = () => {
    localStorage.removeItem("adminToken");
    window.location.reload();
  };
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  useEffect(() => {
    Axios.get("/admin-find-role", config)
      .then((res) => {
        setRole(res.data.msg);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const data = role && role.find((role) => role.email === findAdmin.email);
    setShowRole(data);
  }, [role]);

  return (
    <>
      <div className="center" data-aos={"fade-right"}>
        <ul>
          <p className="title">MAIN</p>
          {/* <p className="title">LISTS</p> */}
          {showRole &&
            showRole.Role &&
            showRole.Role.map((data, i) => (
              <div key={i}>
                {data === "dashboard" && (
                  <Link
                    to="/admin/dashboard"
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <DashboardIcon className="icon" />
                      <span>Dashboard</span>
                    </li>
                  </Link>
                )}
                {data === "users" && (
                  <Link
                    to="/dashboard/users"
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <PersonOutlineIcon className="icon" />
                      <span>Users</span>
                    </li>
                  </Link>
                )}
                {data === "dummy" && (
                  <Link to="/dashboard/bots" style={{ textDecoration: "none" }}>
                    <li>
                      <PersonOutlineIcon className="icon" />
                      <span>Bots Data</span>
                    </li>
                  </Link>
                )}
                {data === "paymentNumber" && (
                  <Link
                    to="/dashboard/payment"
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <PaidIcon className="icon" />
                      <span>Payment Number</span>
                    </li>
                  </Link>
                )}
                {data === "deposite" && (
                  <Link
                    to="/dashboard/user-deposit"
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <StoreIcon className="icon" />
                      <span>Deposit List</span>
                    </li>
                  </Link>
                )}
                {data === "withdraw" && (
                  <Link
                    to="/dashboard/user-withdraw"
                    style={{ textDecoration: "none" }}
                  >
                    <li>
                      <CreditCardIcon className="icon" />
                      <span>Withdraw List</span>
                    </li>
                  </Link>
                )}
                {data === "subAdmin" && (
                  <Link to="/dashboard/sub-admin">
                    <li>
                      <SupervisorAccountIcon className="icon" />
                      <span>Sub Admin</span>
                    </li>
                  </Link>
                )}
                {data === "subAdminList" && (
                  <Link to="/dashboard/sub-admin-list">
                    <li>
                      <AdminPanelSettingsIcon className="icon" />
                      <span>Sub Admin List</span>
                    </li>
                  </Link>
                )}
                {data === "gamePlay" && (
                  <Link to="/dashboard/all-games-play">
                    <li>
                      <RocketLaunchIcon className="icon" />
                      <span>Games Play</span>
                    </li>
                  </Link>
                )}
                {data === "games" && (
                  <Link to="/dashboard/all-user-games">
                    <li>
                      <GamesIcon className="icon" />
                      <span>Games List</span>
                    </li>
                  </Link>
                )}
                {data === "support" && (
                  <Link to="/dashboard/all-user-support">
                    <li>
                      <SupportAgentIcon className="icon" />
                      <span>Support</span>
                    </li>
                  </Link>
                )}
              </div>
            ))}

          <p className="title">USER</p>
          <li onClick={adminLogOut}>
            <ExitToAppIcon className="icon" />
            <span>Logout</span>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
