import React from "react";
import copy from "copy-to-clipboard";
import { toast } from "react-toastify";

const PaytmOption = ({
  onChangeEventHandler,
  value,
  submitPaymentDetails,
  onChangeImage,
  imageFile,
  setImageFile,
  button,
  amount,
  setAmount,
  getPaymentNumber,
}) => {
  const copyClipBoardNumber = () => {
    copy(
      getPaymentNumber &&
        getPaymentNumber.payTm &&
        getPaymentNumber.payTm.payTmNumber
    );
    toast.success(`coppied`);
  };

  return (
    <>
      <div
        className="deposit-right deposit-righttt position-relative"
        id="depoidid2"
      >
        <div className="deposit-right-top d-flex justify-content-between align-items-center">
          <h4>Specify the Transaction ID</h4>
          <div className="id">ID : 11250422</div>
        </div>
        <p>For deposit please send the money to the following credentials</p>
        <div className="deposit-detail position-relative">
          <img src="./images/deposit_right.png" className="deposit_right_img" />
          <h5>1. Copy and Send Money</h5>
          <div className="upi_id_style">
            <div className="upi_id_style_qr">
              <img
                src={
                  getPaymentNumber &&
                  getPaymentNumber.payTm &&
                  getPaymentNumber.payTm.paytmQr
                }
                alt="QR"
              />
            </div>
            <div className="upi_id_style_p">
              <h2>UPI QR</h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's.
              </p>
            </div>
          </div>
          <div className="upi_id id_text">
            <h5>
              <span className="upi_text">UPI id :</span>
              <input
                type="text"
                id="asas"
                value={
                  getPaymentNumber &&
                  getPaymentNumber.payTm &&
                  getPaymentNumber.payTm.payTmNumber
                }
                className="input inputID"
                placeholder={`${
                  getPaymentNumber &&
                  getPaymentNumber.payTm &&
                  getPaymentNumber.payTm.payTmNumber
                }`}
              />
            </h5>
            <button
              className="main-btn login-btn copy-btn"
              onClick={copyClipBoardNumber}
            >
              <img src="./images/cope_ventor.png" />
              Copy
            </button>
          </div>
          <div className="upi_id">
            <img src="./images/paytm.png" style={{ width: "45px" }} />
            <img src="./images/dot_middle_line.png" />
            <h5>
              <span className="upi_text_rupay">₹</span>
              <input
                type="text"
                className="input"
                placeholder="300.00"
                name="amount"
                value={amount === null ? "" : amount}
                onChange={(e) => setAmount(e.target.value)}
              />
            </h5>

            <div className="payment-range">
              <p>₹ 300 - ₹ 70,000 mix*</p>
            </div>
          </div>
          <h5 className="mt-4 mb-3">2. Add Transaction Details</h5>
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="transcation"
              value={value.transcation}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">Your Transcation ID</label>
          </div>
          {/* <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="upiid"
              value={value.upiid}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">YOUR UPI ID</label>
          </div> */}
          <div className="form-group floating">
            <input
              type="text"
              className="form-control floating"
              id="usr"
              name="number"
              value={value.number}
              onChange={(e) => onChangeEventHandler(e)}
              required
            />
            <label htmlFor="usr">YOUR MOBILE NUMBER</label>
          </div>
          <div className="form-group floating uploadImg">
            {imageFile ? (
              <>
                <p className="cross-icon-change-p">
                  {imageFile && imageFile.name}
                </p>
                <img
                  src="./images/model_close.png"
                  className="cross-icon-change"
                  onClick={() => {
                    setImageFile(null);
                    toast.success("transaction image removed successfully ");
                  }}
                />
              </>
            ) : (
              <>
                <div className="mail_img" id="mailIMGShoe">
                  <img src="./images/photo.png" />
                  <div className="uploadimageshow">
                    <img src="#" id="imgshow" />
                    <img
                      src="./images/uploadImg_vector.png"
                      className="uploadimg_vector"
                    />
                  </div>
                </div>
                <input
                  type="text"
                  className="form-control floating uploadImgTExt"
                  id="uloadimgName"
                  name="imgmessage"
                  value={value.imgmessage}
                  onChange={(e) => onChangeEventHandler(e)}
                  required
                />
                <label htmlFor="usr">
                  Payment Transaction Screenshot Attached
                </label>
                <div className="uploadClick">
                  <input type="file" id="FileClick" onChange={onChangeImage} />
                  <div className="mail_img" id="upload-file">
                    <img src="./images/upload-image.png" />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="back_payment back-arrow">
            {button === true ? (
              <button className="main-btn login-btn next-page">
                <img src="./images/next_page_vector.png" />
              </button>
            ) : (
              <button
                className="main-btn login-btn next-page"
                onClick={submitPaymentDetails}
              >
                <img src="./images/next_page_vector.png" />
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PaytmOption;
