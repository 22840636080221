import React from "react";
import gif from "./Running heart.gif";

const Loader = () => {
  return (
    <>
      <div className="loader-center">
        <img src={gif} alt="loader" className="" />
      </div>
    </>
  );
};

export default Loader;
