import React from "react";
import { Helmet } from "react-helmet";
import AdminHeader from "./common/AdminHeader";
import SideBar from "./SideBar";
import LogoImage from "./Flynwin_logo.png";

const Welcome = () => {
  return (
    <>
      <Helmet>
        <title>Sub Admin</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="datatable mt-3">
                <div className="welcome-page">
                  <h1 className="mt-5" style={{ color: "#000" }}>
                    <img src={LogoImage} alt="logo" />
                  </h1>
                  <br />
                  <h3 style={{ color: "#000" }}>Welcome Dashboard Page</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
