import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "../../Navbar/Navbar";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const PersonalInformation = ({
  inputChangeHandler,
  value,
  setShowDate,
  showDate,
}) => {
  const navigate = useNavigate();

  const clickRegisterHandler = () => {
    if (!value.name) {
      toast.error("Enter First Name");
    } else if (!value.lastName) {
      toast.error("Enter a Last Name");
    } else if (!value.type) {
      toast.error("Select Your Gender");
    } else if (!showDate) {
      toast.error("Enter a Date of Birth");
    } else if (!value.language_Type) {
      toast.error("Select Your Language");
    } else {
      navigate("/addressinformation");
    }
  };

  return (
    <>
      <Navbar />
      <section id="PersonalInfo" data-aos={"fade-right"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Login to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/peronalInformation_right.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" alt="" />
                    </Link>
                  </div>
                  <img
                    src="./images/register_right_bottom.png"
                    className="register_right_bottom"
                  />
                  <img
                    src="./images/register_right_top.png"
                    className="register_right_top"
                  />
                  <div className="login_informention Register LoginperonalInformation position-relative">
                    <img
                      src="./images/personalInfo_bg.png"
                      className="login_bg_IMG"
                    />
                    <div className="login_content login_content2">
                      <h4>Personal Information</h4>
                      <div className="Login_line">
                        <div className="line_dots">
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                          <div className="line_dot"></div>
                          <div className="line_dot"></div>
                        </div>
                      </div>
                      <div className="form-section row">
                        <div className="col-lg-6">
                          <div className="form-group floating">
                            <input
                              type="text"
                              name="name"
                              value={value.name}
                              onChange={(e) => inputChangeHandler(e)}
                              className="form-control floating"
                              id="usr"
                              autoComplete="new-password"
                              required
                            />
                            <label htmlFor="usr">First Name</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group floating">
                            <input
                              type="text"
                              name="lastName"
                              className="form-control floating"
                              value={value.lastName}
                              onChange={(e) => inputChangeHandler(e)}
                              id="usr"
                              autoComplete="new-password"
                              required
                            />
                            <label htmlFor="usr">Last Name</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group floating">
                            <select
                              className="form-select form-control floating"
                              id="Gender"
                              aria-label="Default select example"
                              name="type"
                              onChange={(e) => inputChangeHandler(e)}
                            >
                              <option>
                                {value.type ? value.type : "Select"}
                              </option>
                              <option defaultValue="Male">Male</option>
                              <option defaultValue="Female">Female</option>
                              <option defaultValue="Other">Other</option>
                            </select>
                            <label htmlFor="Gender">Gender</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group floating dateOfBirth">
                            <DatePicker
                              selected={showDate}
                              onChange={(e) => setShowDate(e)}
                              dateFormatCalendar="MMMM"
                              minDate={moment().subtract(150, "years")._d}
                              maxDate={moment().subtract(18, "years")._d}
                              showYearDropdown
                              scrollableYearDropdown
                              name="dob"
                              value={moment(showDate).format("DD MMM YYYY")}
                              className="form-control floating"
                            />
                            <label htmlFor="DOB">
                              {showDate.length === 0
                                ? "Date Of Birth"
                                : moment(showDate).format("DD MMM YYYY")}
                            </label>
                            <img
                              src="./images/calendar_vector.png"
                              className="calendar_vector"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group floating">
                            <select
                              className="form-select form-control floating"
                              id="langauge"
                              aria-label="Default select example"
                              name="language_Type"
                              onChange={(e) => inputChangeHandler(e)}
                            >
                              <option>
                                {value.language_Type
                                  ? value.language_Type
                                  : "Selecet"}
                              </option>
                              <option defaultValue="hindi">Hindi</option>
                              <option defaultValue="english">English</option>
                            </select>
                            <label htmlFor="langauge">langauge</label>
                          </div>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <button
                            className="main-btn login-btn next-page"
                            onClick={clickRegisterHandler}
                          >
                            <img src="./images/next_page_vector.png" alt="" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="back_button_content">
                      <Link to="/register" className="back_button_content_a">
                        <button className="main-btn login-btn user-btn">
                          <img
                            src="./images/back_btn.png"
                            className="login-btn-img"
                          />
                          back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PersonalInformation;
