import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { onMailData } from "../../../redux/homeAction";
import Navbar from "../../Navbar/Navbar";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const submitMailData = () => {
    const data = {
      to: email,
    };
    if (!email) {
      toast.error("Please enter email");
    } else if (!regex.test(email)) {
      toast.error("Enter a Correct Email Address");
    } else {
      dispatch(onMailData(data, navigate));
    }
  };
  return (
    <>
      <Navbar />
      <section id="ForgetPassword" data-aos={"fade-right"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Forgot Password to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/verify_right_bg.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" />
                    </Link>
                  </div>
                  <img
                    src="./images/register_right_bottom.png"
                    className="register_right_bottom"
                  />
                  <img
                    src="./images/register_right_top.png"
                    className="register_right_top"
                  />
                  <div className="login_informention Register LoginperonalInformation Address position-relative">
                    <img
                      src="./images/Forget_right_bg.png"
                      className="login_bg_IMG"
                    />
                    <div className="login_content login_content2">
                      <h5 className="Forget_text">
                        Enter the email of your FlyNwin account and we will send
                        you instructions on how to reset your password
                      </h5>
                      <div className="form-section row">
                        <div className="col-12">
                          <div className="form-group floating">
                            <input
                              type="email"
                              className="form-control floating"
                              id="usr"
                              onChange={(e) => setEmail(e.target.value)}
                              value={email}
                              required=""
                            />
                            <label htmlFor="usr">Email address</label>
                          </div>
                        </div>
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <button
                            className="main-btn login-btn next-page"
                            onClick={submitMailData}
                          >
                            <img src="./images/next_page_vector.png" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="back_button_content">
                      <Link to="/login" className="back_button_content_a">
                        <button className="main-btn login-btn user-btn">
                          <img
                            src="./images/back_btn.png"
                            className="login-btn-img"
                          />
                          back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ForgetPassword;
