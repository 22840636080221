import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { onUserImage } from "../../../redux/homeAction";

const ImageDrop = () => {
  const dispatch = useDispatch();
  const getOneUserData = useSelector((state) => state.home.getOneUserData);

  const useerLogin = JSON.parse(localStorage.getItem("user-details"));

  const onChangeImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("_id", useerLogin && useerLogin._id);
    formData.append("image", file);
    if (!file) {
      toast.error("Please select Image");
    } else {
      dispatch(onUserImage(formData));
    }
  };
  // const base64String = btoa(
  //   String.fromCharCode(
  //     ...new Uint8Array(
  //       getOneUserData &&
  //         getOneUserData.image &&
  //         getOneUserData.image.data &&
  //         getOneUserData.image.data.data
  //     )
  //   )
  // );

  return (
    <div>
      <div className="upload__image-wrapper">
        <div className="uploadClick">
          {getOneUserData && getOneUserData.image ? (
            <img
              src={getOneUserData && getOneUserData.image}
              alt="img"
              className="user_dp_camera_img"
            />
          ) : (
            // <img src={DummmyImage} alt="img" />
            <p
              className="user_dp_camera_img"
              style={{
                backgroundColor: `#${
                  getOneUserData && getOneUserData.colorCode
                }`,
                textTransform: "uppercase",
              }}
            >
              {getOneUserData && getOneUserData.name.slice(0, 2)}
            </p>
          )}
          <input
            type="file"
            id="FileClick"
            onChange={onChangeImage}
            style={{ cursor: "pointer" }}
          />
          <div className="mail_img" id="upload-file">
            <i className="fa fa-camera user_dp_camera_upload"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageDrop;
