import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import moment from "moment";
import { Helmet } from "react-helmet";
import Loader from "../Loader/Loader";

const SupportSystem = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const config = {
      headers: {
        Authorization: `${localStorage.getItem("adminToken")}`,
      },
    };
    Axios.get("/support-system", config)
      .then((res) => {
        setUserData(res.data.data);
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  const columns = useMemo(() => [
    { field: "number", headerName: "Number", width: 150 },
    { field: "_id", hide: true },
  ]);
  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  const viewPage = [
    {
      field: "createdAt",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            <div className="view-page-details">
              {moment(params.row.createdAt).format("DD MMM YYYY , h:mm a")}
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              {loader === true ? (
                <Loader />
              ) : (
                <div className="datatable">
                  <div className="datatableTitle">Support Details</div>
                  <DataGrid
                    className="datagrid"
                    columns={columns.concat(viewPage)}
                    rows={rows}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupportSystem;
