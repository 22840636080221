import React from "react";

const AddModel = ({ dummyDataAdded, inputHandlerEvent, value }) => {
  return (
    <>
      <div
        className="modal fade"
        id="dummyAddData"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ color: "#000" }}
              >
                Add Bots Data
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6 dummy_AddData">
                  <label htmlFor="">Enter a email</label>
                  <input
                    type="text"
                    placeholder="enter a email id"
                    onChange={(e) => inputHandlerEvent(e)}
                    name="email"
                    value={value.email}
                  />
                </div>
                <div className="col-md-6 dummy_AddData">
                  <label htmlFor="">Enter a name</label>
                  <input
                    type="text"
                    placeholder="enter a name"
                    onChange={(e) => inputHandlerEvent(e)}
                    name="name"
                    value={value.name}
                  />
                </div>
                <div className="col-md-6 dummy_AddData">
                  <label htmlFor="">Balance</label>
                  <input
                    type="text"
                    placeholder="enter a balance"
                    onChange={(e) => inputHandlerEvent(e)}
                    name="balance"
                    value={value.balance}
                  />
                </div>
                <div className="col-md-6 dummy_AddData">
                  <label htmlFor="">Cashout Value</label>
                  <input
                    type="text"
                    placeholder="enter a balance"
                    onChange={(e) => inputHandlerEvent(e)}
                    name="cashout"
                    value={value.cashout}
                  />
                </div>

                <div className="col-md-12 dummy_AddData">
                  <button
                    type="submit"
                    className="btn btn-primary mt-2"
                    onClick={dummyDataAdded}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddModel;
