import React, { useEffect, useState } from "react";
import WithdrawRightSide from "./WithdrawRightSide";
import { useDispatch, useSelector } from "react-redux";
import { onOneUsersData, onWithdraw } from "../../../redux/homeAction";
import { toast } from "react-toastify";
import validator from "validator";

const Withdraw = () => {
  const [show, setShow] = useState(0);
  const [value, setValue] = useState("");
  const [button, setButton] = useState(false);

  const dispatch = useDispatch();
  const getOneUserData = useSelector((state) => state.home.getOneUserData);
  var upiId = /[a-zA-Z0-9_]{3,}@[a-zA-Z]{3,}/;

  const inputChangeHandler = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  const regex = "^[A-Za-z]{4}[a-zA-Z0-9]{7}$";

  const userId = JSON.parse(localStorage.getItem("user-details"));

  useEffect(() => {
    const data = {
      _id: userId && userId._id,
    };
    dispatch(onOneUsersData(data));
  }, [dispatch]);

  const submitWithdrawDetails = () => {
    const data = {
      id: userId && userId._id,
      Userid: userId && userId._id,
      accountNum: value.accountNumber,
      ifscCode: value.ifscCode,
      accountName: value.accountHolederName,
      number: value.number,
      status: "pending",
      upiId: value.upiId,
      amount: value.amount,
    };
    if (!value.accountNumber) {
      toast.error("Please enter a account Number");
    } else if (value.accountNumber < 9) {
      toast.error("Enter valid account number");
    } else if (!value.ifscCode) {
      toast.error("Please enter IFSC code");
    } else if (!value.ifscCode.match(regex)) {
      toast.error("You entered invalid IFSC code");
    } else if (!value.accountHolederName) {
      toast.error("Please enter Account Holder Name");
    } else if (!value.number) {
      toast.error("Please enter Mobile Number");
    } else if (!validator.isMobilePhone(value.number)) {
      toast.error("Please enter correct mobile number");
    } else if (!value.upiId) {
      toast.error("Please enter UPI ID");
    } else if (!upiId.test(value.upiId)) {
      toast.error("Enter a Correct upi id ");
    } else if (!value.amount) {
      toast.error("Please enter Amount");
    } else if (value.amount < 300) {
      toast.error("Please enter amount more than 300 hundred");
    } else if (
      getOneUserData &&
      parseFloat(getOneUserData.balance) < parseFloat(value.amount)
    ) {
      toast.error("Insufficient balance please check your chips");
    } else {
      dispatch(onWithdraw(data));
      setButton(true);
    }
  };

  return (
    <>
      <div
        className="modal review-model show"
        id="exampleModal2"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content position-relative">
            <img
              src="./images/model_left.png"
              className="img-fluid model_left_img"
            />

            <div className="modal-body">
              <div className="row justify-content-between g-3">
                <div className="col-lg-4">
                  <div className="deposit-left">
                    <div className="deposit-left-top">
                      <img
                        src="./images/model_close.png"
                        data-bs-dismiss="modal"
                        className="model_close"
                      />
                      <h1>Withdraw</h1>
                    </div>
                    <div className="deposit_option" id="widthraw_option">
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          show === 0 ? "active" : ""
                        }`}
                        onClick={() => setShow(0)}
                      >
                        <a>UPI</a>
                        <img src="./images/upi.png" className="img-fluid" />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          show === 1 ? "active" : ""
                        }`}
                        onClick={() => setShow(1)}
                      >
                        <a>paytm</a>
                        <img src="./images/paytm.png" className="img-fluid" />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          show === 2 ? "active" : ""
                        }`}
                        onClick={() => setShow(2)}
                      >
                        <a>phonePay</a>
                        <img
                          src="./images/phonePay.png"
                          className="img-fluid"
                        />
                      </div>
                      <div
                        className={`widthraw_options deposit_options upi_deposit ${
                          show === 3 ? "active" : ""
                        }`}
                        onClick={() => setShow(3)}
                      >
                        <a>googlePay</a>
                        <img
                          src="./images/googlePay.png"
                          className="img-fluid"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-1 model_middle_line">
                  <img src="./images/Line_middle.png" />
                </div>
                <div className="col-lg-7">
                  <WithdrawRightSide
                    inputChangeHandler={inputChangeHandler}
                    submitWithdrawDetails={submitWithdrawDetails}
                    value={value}
                    button={button}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
