import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import { toast } from "react-toastify";
import Axios from "../../redux/axios";
import { Helmet } from "react-helmet";
import Loader from "../Loader/Loader";

const Datatable = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  useEffect(() => {
    setLoader(true);
    Axios.get("/get-all-users", config)
      .then((res) => {
        setUserData(res.data.users);
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  const handleChangeDeleteUser = (id) => {
    Axios.delete(`/get-all-users/` + id, config)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {});
  };

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "User Name",
      width: 230,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {params.row.image ? (
              <img className="cellImg" src={params.row.image} alt="avatar" />
            ) : (
              <p
                className="cellImg"
                style={{
                  backgroundColor: `#${params.row.colorCode}`,
                  textTransform: "uppercase",
                }}
              >
                {params.row.name.slice(0, 2)}
              </p>
            )}
            {params.row.name}
          </div>
        );
      },
    },
    { field: "email", headerName: "Email", width: 230 },
    { field: "balance", headerName: "User Chips", width: 150 },
    { field: "_id", hide: true },
    { field: "city", headerName: "User City", width: 150 },
  ]);

  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={`/dashboard/user-details/${params.row.name}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              onClick={() => handleChangeDeleteUser(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="datatable">
                {loader === true ? (
                  <Loader />
                ) : (
                  <>
                    <div className="datatableTitle">All Users</div>
                    <DataGrid
                      className="datagrid"
                      columns={columns.concat(actionColumn)}
                      rows={rows}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Datatable;
