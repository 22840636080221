import { Identity } from "@mui/base";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import Loader from "../Loader/Loader";
import SideBar from "../SideBar";
import AddModel from "./AddModel";

const DummyUser = () => {
  const [userData, setUserData] = useState([]);
  const [cashoutInput, setCashoutInput] = useState("");
  const [value, setValue] = useState("");
  const [loader, setLoader] = useState(false);
  const [reloadBots, setReloadBots] = useState("");
  const [inputShow, setInputShow] = useState("");
  const [input, setInput] = useState("");
  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };

  const inputHandlerEvent = (e) => {
    setValue({ ...value, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    setLoader(true);
    Axios.get("/dummy-users", config)
      .then((res) => {
        setUserData(res.data.users);
        setLoader(false);
      })
      .catch((err) => {});
  }, [reloadBots]);

  const columns = useMemo(() => [
    {
      field: "name",
      headerName: "User Name",
      width: 100,
      renderCell: (params) => {
        return <div className="cellWithImg">{params.row.name}</div>;
      },
    },
    { field: "email", headerName: "Email", width: 150 },
    { field: "balance", headerName: "Bot Chips", width: 150 },
    { field: "dummyBetValue", headerName: "Bot Bet Value", width: 80 },
    { field: "cashoutValue", headerName: "Bot Cashout Value", width: 80 },
    { field: "_id", hide: true },
  ]);

  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  const botsCashoutValue = (id) => {
    setInputShow(id);
    setCashoutInput("");
    setInput("");
  };

  const handleChangeDeleteUser = (id) => {
    Axios.delete(`/dummy-delete/` + id, config).then((res) => {
      if (res.data.status === true) {
        setReloadBots(res.data.message);
        toast.success(res.data.message);
      } else {
        toast.success(res.data.message);
      }
    });
  };

  const submitCashoutData = () => {
    if (!input) {
      toast.error("please enter a bot bet value");
    } else {
      const data = {
        cashoutValue: input,
        user_id: inputShow,
      };
      Axios.post("/cashout-bots", data, config)
        .then((res) => {
          toast.success(res.data.message);
          setReloadBots(res.data.message);
          setInput("");
          setInputShow("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addCashoutInDummy = (id) => {
    setCashoutInput(id);
    setInputShow("");
    setInput("");
  };

  const submitDummyBetsValue = () => {
    if (!input) {
      toast.error("please enter a bot cashout value");
    } else {
      const data = {
        dummyBetValue: input,
        user_id: cashoutInput,
      };
      Axios.post("/dummy-bet-value", data, config)
        .then((res) => {
          setReloadBots(res.data.message);
          toast.success(res.data.message);
          setInput("");
          setInputShow("");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 500,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {params.row._id === inputShow ? (
              <div className="cashout-input-value">
                <input
                  type="text"
                  placeholder="enter cashout value"
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                />
                <button type="button" onClick={submitCashoutData}>
                  send
                </button>
              </div>
            ) : (
              <div
                className="deleteButton"
                onClick={() => botsCashoutValue(params.row._id)}
              >
                Edit
              </div>
            )}
            <div
              className="deleteButton"
              onClick={() => handleChangeDeleteUser(params.row._id)}
            >
              Delete
            </div>
            {cashoutInput === params.row._id ? (
              <div className="cashout-input-value">
                <input
                  type="text"
                  placeholder="enter bet value"
                  onChange={(e) => setInput(e.target.value)}
                  value={input}
                />
                <button type="button" onClick={submitDummyBetsValue}>
                  send
                </button>
              </div>
            ) : (
              <div
                className="deleteButton"
                onClick={() => addCashoutInDummy(params.row._id)}
              >
                Add Bet value
              </div>
            )}
          </div>
        );
      },
    },
  ];

  const dummyDataAdded = () => {
    if (!value.email) {
      toast.error("please enter a email");
    } else if (!value.name) {
      toast.error("please enter a name");
    } else if (!value.balance) {
      toast.error("please enter a balance");
    } else if (!value.cashout) {
      toast.error("please enter a cashout Number");
    } else {
      const data = {
        email: value.email,
        name: value.name,
        balance: value.balance,
        typeData: "dummy",
        cashoutValue: value.cashout,
      };

      Axios.post("/dummy-add-data", data, config)
        .then((res) => {
          if (res.data.status === true) {
            toast.success(res.data.message);
            setReloadBots(res.data.message);
            window.location.reload();
          } else {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              <div className="datatable">
                {loader === true ? (
                  <Loader />
                ) : (
                  <>
                    <div className="set_dummy_button">
                      <div className="datatableTitle">All Bots Data</div>

                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#dummyAddData"
                      >
                        Add More
                      </button>
                    </div>
                    <DataGrid
                      className="datagrid"
                      columns={columns.concat(actionColumn)}
                      rows={rows}
                      pageSize={9}
                      rowsPerPageOptions={[9]}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddModel
        dummyDataAdded={dummyDataAdded}
        inputHandlerEvent={inputHandlerEvent}
        value={value}
      />
    </>
  );
};

export default DummyUser;
