import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";

const VerifyAccountDetails = () => {
  return (
    <>
      <Navbar />
      <section id="VerifyAccount" data-aos={"fade-right"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Login to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/verify_right_bg.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" />
                    </Link>
                  </div>
                  <img
                    src="./images/register_right_bottom.png"
                    className="register_right_bottom"
                  />
                  <img
                    src="./images/register_right_top.png"
                    className="register_right_top"
                  />
                  <div className="login_informention Register LoginperonalInformation Address position-relative">
                    <img
                      src="./images/address-bg.png"
                      className="login_bg_IMG"
                    />
                    <div className="login_content login_content2">
                      <h4>Verify Your Account</h4>
                      <div className="Login_line">
                        <div className="line_dots">
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                        </div>
                      </div>
                      <div className="verify_number_section">
                        <h5>We have sent an CODE on your number:</h5>
                        <h4>+91 9876543210</h4>
                        <a className="wrong_number">Wrong Number</a>
                        <div className="number_inputs">
                          <input type="text" className="number_input" />
                          <input type="text" className="number_input" />
                          <input type="text" className="number_input" />
                          <input type="text" className="number_input" />
                          <input type="text" className="number_input" />
                          <input type="text" className="number_input" />
                        </div>
                        <a className="resend_code">Resend Code : 30s</a>
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <Link to="#">
                            <button className="main-btn login-btn next-page">
                              <img src="./images/next_page_vector.png" />
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="back_button_content">
                      <Link
                        to="/addressinformation"
                        className="back_button_content_a"
                      >
                        <button className="main-btn login-btn user-btn">
                          <img
                            src="./images/back_btn.png"
                            className="login-btn-img"
                          />
                          back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyAccountDetails;
