import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import AdminHeader from "../common/AdminHeader";
import SideBar from "../SideBar";
import Axios from "../../redux/axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from "../Loader/Loader";

const Withdraw = () => {
  const [userData, setUserData] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    const config = {
      headers: {
        Authorization: `${localStorage.getItem("adminToken")}`,
      },
    };
    Axios.get("/get-withdraw-data", config)
      .then((res) => {
        setUserData(res.data.withdraw);
        setLoader(false);
      })
      .catch((err) => {});
  }, []);
  const columns = useMemo(() => [
    { field: "amount", headerName: " Amount", width: 100 },
    { field: "ifscCode", headerName: " Ifsc", width: 150 },
    { field: "accountName", headerName: "Account Name", width: 150 },
    { field: "accountNum", headerName: "Account Number", width: 150 },
    { field: "_id", hide: true },
    { field: "number", headerName: "Number", width: 150 },
  ]);

  const statusShow = [
    {
      field: "status",
      headerName: "status",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "pending" ? (
              <div className="status pending">{params.row.status}</div>
            ) : params.row.status === "accept" ? (
              <div className="status accept">{params.row.status}</div>
            ) : params.row.status === "reject" ? (
              <div className="status reject">{params.row.status}</div>
            ) : null}
          </>
        );
      },
    },
  ];

  const viewPage = [
    {
      field: "action",
      headerName: "view",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <Link
              to={`/withdraw-details/${JSON.stringify({
                id: params.row._id,
                user_id: params.row.Userid,
              })}`}
            >
              <div className="view-page-details">View</div>
            </Link>
          </>
        );
      },
    },
  ];

  const rows = useMemo(
    () => userData && userData.map((row, index) => ({ ...row, id: row._id })),
    [userData]
  );

  return (
    <>
      <Helmet>
        <title>DashBoard</title>
      </Helmet>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          {loader === true ? (
            <Loader />
          ) : (
            <div className="row">
              <div className="col-lg-2">
                <SideBar />
              </div>
              <div className="col-lg-10">
                <div className="datatable">
                  <div className="datatableTitle">Withdraw Details</div>
                  <DataGrid
                    className="datagrid"
                    columns={columns.concat(statusShow, viewPage)}
                    rows={rows}
                    pageSize={9}
                    rowsPerPageOptions={[9]}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Withdraw;
