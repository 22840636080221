import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../Navbar/Navbar";

const AddressInformation = ({ value, inputChangeHandler, submitOnHandler }) => {
  return (
    <>
      <Navbar />
      <section id="AddressInfo" data-aos={"fade-right"}>
        <div className="container-fluid">
          <div className="login_container">
            <div className="row">
              <div className="col-lg-6">
                <div className="login_right">
                  <div className="welcome_content">
                    <h1>Welcome Back</h1>
                    <h5>Login to FlyNwin account</h5>
                  </div>
                  <div className="login_right_img_section">
                    <img
                      src="./images/peronalInformation_right.png"
                      className="img-fluid login_right_img"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="login_left">
                  <div className="close_page">
                    <Link to="/">
                      <img src="./images/close_pink.png" />
                    </Link>
                  </div>
                  <img
                    src="./images/register_right_bottom.png"
                    className="register_right_bottom"
                  />
                  <img
                    src="./images/register_right_top.png"
                    className="register_right_top"
                  />
                  <div className="login_informention Register LoginperonalInformation Address position-relative">
                    <img
                      src="./images/address-bg.png"
                      className="login_bg_IMG"
                    />
                    <div className="login_content login_content2">
                      <h4>Address Information</h4>
                      <div className="Login_line">
                        <div className="line_dots">
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                          <div className="line_dot complate"></div>
                          <div className="line_dot"></div>
                        </div>
                      </div>
                      <div className="form-section row">
                        <div className="col-12">
                          <div className="form-group floating">
                            <input
                              type="text"
                              className="form-control floating"
                              id="DOB"
                              name="country"
                              value={value.country}
                              onChange={(e) => inputChangeHandler(e)}
                              placeholder="country"
                              autoComplete="new-password"
                            />
                            <label htmlFor="Country">Country</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group floating">
                            <input
                              type="text"
                              className="form-control floating"
                              name="state"
                              autoComplete="new-password"
                              id="DOB"
                              value={value.state}
                              onChange={(e) => inputChangeHandler(e)}
                              placeholder="state"
                            />
                            <label htmlFor="Country">state</label>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group floating">
                            <input
                              type="text"
                              name="city"
                              className="form-control floating"
                              id="DOB"
                              autoComplete="new-password"
                              value={value.city}
                              onChange={(e) => inputChangeHandler(e)}
                              placeholder="city"
                            />
                            <label htmlFor="Country">City</label>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group floating">
                            <input
                              type="text"
                              className="form-control floating"
                              id="referral1"
                              name="referral"
                              value={value.referral}
                              onChange={(e) => inputChangeHandler(e)}
                            />
                            <label htmlFor="referral1">Referral Code</label>
                          </div>
                        </div>
                        {/* <div className="col-lg-12">
                          <div className="form-group floating">
                            <input
                              type="number"
                              className="form-control floating"
                              id="Address"
                              name="zipCode"
                              value={value.zipCode}
                              onChange={(e) => inputChangeHandler(e)}
                            />
                            <label htmlFor="Address">Zip Code</label>
                          </div>
                        </div> */}
                      </div>
                      <div className="button-section">
                        <div className="next_button">
                          <button
                            className="main-btn login-btn next-page"
                            onClick={submitOnHandler}
                          >
                            <img src="./images/next_page_vector.png" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="back_button_content">
                      <Link
                        to="/personalinformation"
                        className="back_button_content_a"
                      >
                        <button className="main-btn login-btn user-btn">
                          <img
                            src="./images/back_btn.png"
                            className="login-btn-img"
                          />
                          back
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddressInformation;
