import React, { useContext, useEffect, useRef, useState } from "react";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
import Loader from "../Loader/Loader";
import { SocketContext } from "../../SocketContext/SocketContext";
import { toast } from "react-toastify";
import Navbar from "../Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onOneUsersData } from "../../redux/homeAction";
import DepositModel from "../Models/DepositModel/DepositModel";
import Axios from "../../redux/axios";
import { Offline, Online } from "react-detect-offline";

const BetDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loader, setLoader] = useState(false);
  const [count, setCount] = useState(0);
  const [waitingVisible, setWaitingVisible] = useState(false);
  const [rocket, setRocket] = useState(false);
  const [flyAnimationTimeX, setFlyAnimationTimeX] = useState(-92);
  const [flyAnimationTimeY, setFlyAnimationTimeY] = useState(180);
  const [videoPlayOne, setVideoPlayOne] = useState(false);
  const [countColor, setCountColor] = useState("#fbdc4b");
  const [betBtnVisibility, setBetBtnVisibility] = useState(true);
  const [cancelBtnVisibility, setCancelBtnVisibility] = useState(false);
  const [cashoutBtnVisibility, setCashoutBtnVisibility] = useState(false);
  const [gameUsers, setGameUsers] = useState([]);
  const [cashPlus, setCashPlus] = useState(
    parseInt(localStorage.getItem("left-amount")) || 10
  );
  const [cashoutEarning, setCashoutEarning] = useState("");
  const [allBets, setAllBets] = useState([]);
  const [userBets, setUserBets] = useState([]);
  const [autoBet, setAutoBet] = useState("");
  const [auto2Bet, setAuto2Bet] = useState("");
  const [autoBetSecond, setAutoBetSecond] = useState("");
  const [autoBet2Second, setAutoBet2Second] = useState("");
  const { socket } = useContext(SocketContext);
  const [presentPlus, setPresentPlus] = useState(
    localStorage.getItem("left-cashout") || 1.05
  );
  const [topBetsFound, setTopBetsFound] = useState([]);

  //right button
  const [betBtn2Visibility, setBetBtn2Visibility] = useState(true);
  const [cancelBtn2Visibility, setCancelBtn2Visibility] = useState(false);
  const [cashoutBtn2Visibility, setCashoutBtn2Visibility] = useState(false);
  const [present2Plus, setPresent2Plus] = useState(
    localStorage.getItem("right-cashout") || 1.05
  );
  const [cash2Plus, setCash2Plus] = useState(
    localStorage.getItem("right-amount") || 10
  );
  const getOneUserData = useSelector((state) => state.home.getOneUserData);
  const [updateUserData, setUpdateUserData] = useState([]);
  const [modelShow, setModelShow] = useState(false);
  const [model2Show, setModel2Show] = useState(false);
  const [cashoutData, setCashoutData] = useState("");
  const [cashoutShow, setCashoutShow] = useState(false);
  const [cashoutCount, setCashoutCount] = useState(false);
  const [autoBetAccept, setAutoBetAccept] = useState(false);

  const [globalTimeNow, setGlobalTimeNow] = useState(0);
  const [bBettingPhase, setbBettingPhase] = useState(false);
  const [liveMultiplierSwitch, setLiveMultiplierSwitch] = useState(false);
  const multiplierCount = useRef([]);
  const timeCount_xaxis = useRef([]);
  const realCounter_yaxis = useRef(5);

  const user = JSON.parse(localStorage.getItem("user-details"));
  const userLogin = localStorage.getItem("userToken");

  useEffect(() => {
    socket.emit("startgame");
  }, [socket]);
  useEffect(() => {
    const data = {
      _id: user && user._id,
    };
    dispatch(onOneUsersData(data));
  }, [dispatch]);

  useEffect(() => {
    socket.on("take-user-data", (res) => {
      setUpdateUserData(res);
    });
  }, [socket]);

  useEffect(() => {
    localStorage.setItem("left-amount", cashPlus);
    localStorage.setItem("right-amount", cash2Plus);
    localStorage.setItem("left-cashout", presentPlus);
    localStorage.setItem("right-cashout", present2Plus);
  }, [cashPlus, cash2Plus, presentPlus, present2Plus]);

  useEffect(() => {
    if (sessionStorage.getItem("auto2Bets")) {
      if (betBtnVisibility === false) {
        if (parseFloat(presentPlus) <= parseFloat(count)) {
          socket.emit("cashout-game", user._id, "left", presentPlus);
        }
      }
    }
  }, [count]);

  useEffect(() => {
    if (sessionStorage.getItem("autoBet2Second")) {
      if (betBtn2Visibility === false) {
        if (parseFloat(present2Plus) <= parseFloat(count)) {
          socket.emit("cashout-game", user._id, "right", present2Plus);
        }
      }
    }
  }, [count]);

  useEffect(() => {
    if (autoBet === true) {
      sessionStorage.setItem("autoBets", true);
    } else {
      sessionStorage.removeItem("autoBets");
    }
  }, [autoBet]);

  useEffect(() => {
    if (autoBetSecond === true) {
      sessionStorage.setItem("autoBetSecond", true);
    } else {
      sessionStorage.removeItem("autoBetSecond");
    }
  }, [autoBetSecond]);

  useEffect(() => {
    if (auto2Bet === true) {
      sessionStorage.setItem("auto2Bets", true);
    } else {
      sessionStorage.removeItem("auto2Bets");
    }
  }, [auto2Bet]);

  useEffect(() => {
    if (autoBet2Second === true) {
      sessionStorage.setItem("autoBet2Second", true);
    } else {
      sessionStorage.removeItem("autoBet2Second");
    }
  }, [autoBet2Second]);

  const betOnGame = (e) => {
    const currentChips =
      updateUserData.length === 0
        ? getOneUserData && getOneUserData.balance
        : updateUserData.balance;
    if (userLogin) {
      if (parseFloat(currentChips) > parseInt(cashPlus)) {
        socket.emit(
          "join-game",
          user._id,
          parseInt(cashPlus),
          user.name,
          "left",
          user.colorCode
        );
      } else {
        toast.error("chips are insufficient");
        setModelShow(true);
      }
    } else {
      toast.error("Please login First and Play Game");
      navigate("/login");
    }
  };

  const cancelbetOnGame = (e) => {
    socket.emit("cancel-game", user._id, "left");
    toast.error("The bet was cancelled", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
    localStorage.removeItem("bet-on-game");
    localStorage.removeItem("bet-on-game2");
  };

  const cashoutbetOnGame = (e) => {
    socket.emit("cashout-game", user._id, "left");
  };

  useEffect(() => {
    socket.on("output-joinedusers", (gameUsers_res) => {
      let index = -1;
      let rightindex = -1;
      if (user) {
        index = gameUsers_res.findIndex(
          (item) =>
            item.userid === user._id &&
            item.is_cashout == 0 &&
            item.betType == "left"
        );
        rightindex = gameUsers_res.findIndex(
          (item) =>
            item.userid === user._id &&
            item.is_cashout == 0 &&
            item.betType == "right"
        );
      }
      if (index !== -1) {
        setCashoutEarning(gameUsers_res[index].cashPlus);
        setCashoutBtnVisibility(true);
        setBetBtnVisibility(false);
        setCancelBtnVisibility(false);
        localStorage.removeItem("bet-on-game");
      }
      if (rightindex !== -1) {
        setCashoutBtn2Visibility(true);
        setBetBtn2Visibility(false);
        setCancelBtn2Visibility(false);
        localStorage.removeItem("bet-on-game2");
      }
      setGameUsers(gameUsers_res);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("dummy-output-joinedusers", (gameUsers_res) => {
      setGameUsers(gameUsers_res);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("output-user-bets", (res) => {
      setUserBets(res);
      setVideoPlayOne(false);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("output-all-bets", (res) => {
      setAllBets(res);
    });
  }, [socket]);

  useEffect(() => {
    socket.on("game-cashout", (data) => {
      setCashoutData(data.amount);
      setCashoutShow(true);
      setCashoutCount(data.count);
      if (data.type == "left") {
        setCashoutBtnVisibility(false);
        setBetBtnVisibility(true);
        setCancelBtnVisibility(false);
      } else {
        setCashoutBtn2Visibility(false);
        setBetBtn2Visibility(true);
        setCancelBtn2Visibility(false);
      }

      setTimeout(() => {
        setCashoutShow(false);
      }, 4000);

      if (user) {
        socket.emit("get-user-data", user);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket.on("game-canceled", (cancelType) => {
      if (cancelType == "left") {
        setBetBtnVisibility(true);
        setCashoutBtnVisibility(false);
        setCancelBtnVisibility(false);
      } else {
        setBetBtn2Visibility(true);
        setCancelBtn2Visibility(false);
        setCashoutBtn2Visibility(false);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket.on("chips-insufficient", (cancelType) => {
      toast.error("chips are insufficient");
      if (cancelType == "left") {
        setBetBtnVisibility(true);
        setCancelBtnVisibility(false);
        setCashoutBtnVisibility(false);
      } else {
        setBetBtn2Visibility(true);
        setCancelBtn2Visibility(false);
        setCashoutBtn2Visibility(false);
      }
    });
  }, [socket]);

  useEffect(() => {
    socket.on("game-joined", (betType, msg) => {
      if (betType == "left") {
        toast.success(msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setBetBtnVisibility(false);
        setCancelBtnVisibility(true);
        localStorage.setItem("bet-on-game", true);
        setCashoutBtnVisibility(false);
        setAutoBetAccept(false);
      } else {
        toast.success(msg, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setBetBtn2Visibility(false);
        setCancelBtn2Visibility(true);
        localStorage.setItem("bet-on-game2", true);
        setAutoBetAccept(false);
        setCashoutBtn2Visibility(false);
      }
    });
  }, [socket]);

  const showCancelButton = localStorage.getItem("bet-on-game");
  const showCancel2Button = localStorage.getItem("bet-on-game2");

  useEffect(() => {
    if (showCancelButton) {
      setCancelBtnVisibility(true);
      setBetBtnVisibility(false);
    }
  }, [showCancelButton]);

  useEffect(() => {
    if (showCancel2Button) {
      setCancelBtn2Visibility(true);
      setBetBtn2Visibility(false);
    }
  }, [showCancel2Button]);

  useEffect(() => {
    socket.emit("get-joined-users");
  }, [socket]);

  useEffect(() => {
    socket.emit("get-all-bets");
  }, [socket]);

  useEffect(() => {
    socket.emit("all-games-data");
  }, [socket]);

  useEffect(() => {
    socket.on("all-game-bets", (res) => {
      setTopBetsFound(res);
    });
  }, [socket]);

  useEffect(() => {
    if (user) {
      socket.emit("get-user-bets", user._id);
    }
  }, [socket]);

  // useEffect(() => {
  //   socket.on("start-time-now", () => {
  //     socket.emit("get-joined-users");
  //     setBetBtnVisibility(true);
  //     setCashoutBtnVisibility(false);
  //     setCancelBtnVisibility(false);
  //     setBetBtn2Visibility(true);
  //     setCashoutBtn2Visibility(false);
  //     setCancelBtn2Visibility(false);
  //     if (user) {
  //       socket.emit("get-user-data", user);
  //     }
  //   });
  // }, [socket]);

  useEffect(() => {
    socket.on("refresh-users", () => {
      socket.emit("get-joined-users");
    });
  }, [socket]);

  useEffect(() => {
    socket.on("dummy-refresh-users", () => {
      socket.emit("dummy-joined-users");
    });
  }, [socket]);

  useEffect(() => {
    socket.on("update-user", () => {
      socket.emit("get-user-data", user);
    });
  }, [socket, user]);

  // right button

  const betOn2Game = (e) => {
    if (userLogin) {
      const currentChips =
        updateUserData.length === 0
          ? getOneUserData && getOneUserData.balance
          : updateUserData.balance;
      if (parseFloat(currentChips) > parseInt(cash2Plus)) {
        socket.emit(
          "join-game",
          user._id,
          parseInt(cash2Plus),
          user.name,
          "right",
          user.colorCode
        );
        // toast.success("The bet was received");
      } else {
        toast.error("chips are insufficient");
        setModel2Show(true);
      }
    } else {
      toast.error("Please login First and Play Game");
      navigate("/login");
    }
  };

  const cancelbetOn2Game = (e) => {
    socket.emit("cancel-game", user._id, "right");
    toast.error("The bet was cancelled", {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
    localStorage.removeItem("bet-on-game");
    localStorage.removeItem("bet-on-game2");
    // sound.play();
  };

  const cashoutbetOn2Game = (e) => {
    socket.emit("cashout-game", user._id, "right");
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setModelShow(false);
          setModel2Show(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useEffect(() => {
    if (autoBetAccept && sessionStorage.getItem("autoBets")) {
      if (betBtnVisibility) {
        socket.emit(
          "join-game",
          user._id,
          parseInt(cashPlus),
          user.name,
          "left",
          user.colorCode
        );
      }
    }
    if (autoBetAccept && sessionStorage.getItem("autoBetSecond")) {
      if (betBtn2Visibility) {
        socket.emit(
          "join-game",
          user._id,
          parseInt(cash2Plus),
          user.name,
          "right",
          user.colorCode
        );
      }
    }
  }, [autoBetAccept]);
  // Socket.io setup
  useEffect(() => {
    setCashPlus(localStorage.getItem("left-amount"));
    setLoader(true);
    socket.on("start_multiplier_count", function (data) {
      setAutoBetAccept(false);
      setGlobalTimeNow(Date.now());
      setLiveMultiplierSwitch(true);
      setWaitingVisible(false);
      setCountColor("#fbdc4b");
      if (window.innerWidth < 769) {
        setFlyAnimationTimeX(100);
        setFlyAnimationTimeY(15);
      } else {
        setFlyAnimationTimeX(360);
        setFlyAnimationTimeY(15);
      }
      setRocket(false);
      setLoader(false);
      socket.emit("get-joined-users");
      socket.on("all-game-bets", (res) => {
        setTopBetsFound(res);
      });
    });

    socket.on("stop_multiplier_count", (dataUser) => {
      setAutoBetAccept(true);
      setFlyAnimationTimeX(13000);
      setFlyAnimationTimeY(15);
      localStorage.setItem("end-count", dataUser.endTime);
      setTimeout(() => {
        setAutoBetAccept(false);
      }, 100);
      const tempData = JSON.parse(dataUser.userData);
      setCount(dataUser.endTime);
      setLiveMultiplierSwitch(false);
      setCountColor("red");
      if (user) {
        let index = tempData.findIndex((item) => item.userid === user._id);
        if (index === -1) {
          setBetBtnVisibility(true);
          setBetBtn2Visibility(true);
        }
      }
      setRocket(true);
      setCashoutBtnVisibility(false);
      setCashoutBtn2Visibility(false);
      socket.emit("get-all-bets");
      socket.emit("all-games-data");
      setTimeout(() => {
        if (user) {
          socket.emit("get-user-bets", user._id);
        }
      }, 1500);
    });

    socket.on("start_betting_phase", function (data) {
      setAutoBetAccept(false);
      setGlobalTimeNow(Date.now());
      setWaitingVisible(true);
      setbBettingPhase(true);
      setCount(null);
      setFlyAnimationTimeX(-92);
      setFlyAnimationTimeY(180);
      setLoader(false);
      multiplierCount.current = [];
      timeCount_xaxis.current = [];
    });

    socket.on("receive_live_betting_table", (data) => {
      setCount(data);
      data = JSON.parse(data);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (window.performance) {
      if (performance.navigation.type == 1) {
        if (window.innerWidth < 769) {
          setFlyAnimationTimeX(100);
          setFlyAnimationTimeY(15);
        } else {
          setFlyAnimationTimeX(360);
          setFlyAnimationTimeY(15);
        }
      }
    }
  }, []);

  useEffect(() => {
    let gameCounter = null;
    if (liveMultiplierSwitch) {
      setCount("1.00");
      gameCounter = setInterval(() => {
        if (navigator.onLine) {
          let time_elapsed = (Date.now() - globalTimeNow) / 600.0;
          setCount((1.0045 * Math.pow(1.0418, time_elapsed)).toFixed(2));
          setLoader(false);
          if (multiplierCount.current.length < 1) {
            multiplierCount.current = multiplierCount.current.concat([1]);
            timeCount_xaxis.current = timeCount_xaxis.current.concat([0]);
          }
          if (realCounter_yaxis.current % 5 == 0) {
            multiplierCount.current = multiplierCount.current.concat([
              (1.0045 * Math.pow(1.0418, time_elapsed)).toFixed(2),
            ]);
            timeCount_xaxis.current = timeCount_xaxis.current.concat([
              time_elapsed,
            ]);
          }
          realCounter_yaxis.current += 1;
        } else {
        }
      }, 1);
    }
    return () => {
      clearInterval(gameCounter);
    };
  }, [liveMultiplierSwitch]);

  useEffect(() => {
    get_game_status();
  }, []);

  useEffect(() => {
    let bettingInterval = null;

    if (bBettingPhase) {
      bettingInterval = setInterval(() => {
        let time_elapsed = (Date.now() - globalTimeNow) / 1000.0;
        let time_remaining = (3.8 - time_elapsed).toFixed(2);
        // setBettingPhaseTime(time_remaining);
        setWaitingVisible(true);
        if (time_remaining < 0) {
          setbBettingPhase(false);
          setWaitingVisible(false);
        }
      }, 10);
    }
    return () => {
      clearInterval(bettingInterval);
      // setBettingPhaseTime("Starting...");
      setWaitingVisible(false);
    };
  }, [bBettingPhase]);

  let stopBetCount = localStorage.getItem("end-count");

  useEffect(() => {
    setCount(stopBetCount);
  }, [stopBetCount]);

  const get_game_status = () => {
    Axios.get("/get_game_status").then((res) => {
      if (res.data.phase === "betting_phase") {
        setGlobalTimeNow(res.data.info);
        setbBettingPhase(true);
        setWaitingVisible(true);
        setLoader(false);
      } else if (res.data.phase === "game_phase") {
        setGlobalTimeNow(res.data.info);
        setLiveMultiplierSwitch(true);
        setCountColor("#fbdc4b");
        setLoader(false);
      }
    });
  };

  const onFocus = () => {};

  const offFocus = () => {
    // console.log("closed");
    // window.location.reload();
  };

  const onBlur = () => {
    // console.log("blur");
  };

  const WindowFocusHandler = () => {
    useEffect(() => {
      window.addEventListener("focus", onFocus);
      window.addEventListener("blur", onBlur);

      // Specify how to clean up after this effect:
      return () => {
        window.removeEventListener("focus", offFocus);
        window.removeEventListener("blur", onBlur);
      };
    });
  };

  WindowFocusHandler();

  return (
    <>
      <Navbar />
      <section id="Container" ref={wrapperRef}>
        {loader ? (
          <Loader />
        ) : (
          <div className="container-fluid" data-aos={"fade-up"}>
            <div className="row g-3 justify-content-between h-100">
              <LeftSide
                modelShow={modelShow}
                allBets={allBets}
                videoPlayOne={videoPlayOne}
                rocket={rocket}
                flyAnimationTimeX={flyAnimationTimeX}
                flyAnimationTimeY={flyAnimationTimeY}
                model2Show={model2Show}
                setVideoPlayOne={setVideoPlayOne}
                // left button
                betBtnVisibility={betBtnVisibility}
                cancelBtnVisibility={cancelBtnVisibility}
                count={count}
                cashoutBtnVisibility={cashoutBtnVisibility}
                betOnGame={betOnGame}
                waitingVisible={waitingVisible}
                cancelbetOnGame={cancelbetOnGame}
                cashoutbetOnGame={cashoutbetOnGame}
                setCashPlus={setCashPlus}
                cashPlus={cashPlus}
                setAutoBet={setAutoBet}
                setAuto2Bet={setAuto2Bet}
                presentPlus={presentPlus}
                setPresentPlus={setPresentPlus}
                countColor={countColor}
                // right button
                cashoutBtn2Visibility={cashoutBtn2Visibility}
                cancelBtn2Visibility={cancelBtn2Visibility}
                betBtn2Visibility={betBtn2Visibility}
                setCash2Plus={setCash2Plus}
                betOn2Game={betOn2Game}
                setAutoBetSecond={setAutoBetSecond}
                cash2Plus={cash2Plus}
                cancelbetOn2Game={cancelbetOn2Game}
                cashoutbetOn2Game={cashoutbetOn2Game}
                setPresent2Plus={setPresent2Plus}
                present2Plus={present2Plus}
                setAutoBet2Second={setAutoBet2Second}
              />
              <RightSide
                gameUsers={gameUsers}
                userBets={userBets}
                topBetsFound={topBetsFound}
                cashoutData={cashoutData}
                cashoutShow={cashoutShow}
                cashoutCount={cashoutCount}
              />
            </div>
          </div>
        )}
      </section>
      <DepositModel />
    </>
  );
};
export default BetDashboard;
