import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../redux/axios";
import AdminHeader from "../common/AdminHeader";
import Loader from "../Loader/Loader";
import SideBar from "../SideBar";

const WithDrawDetails = () => {
  const { id } = useParams();
  const findData = JSON.parse(id);
  const [userData, setUserData] = useState([]);
  const [userDetails, setUserDetails] = useState("");
  const [loader, setLoader] = useState(false);

  const config = {
    headers: {
      Authorization: `${localStorage.getItem("adminToken")}`,
    },
  };
  useEffect(() => {
    setLoader(true);
    Axios.get("/get-withdraw-data", config)
      .then((res) => {
        setUserData(res.data.withdraw.find((data) => data._id === findData.id));
        setLoader(false);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    Axios.get("/get-all-users", config)
      .then((res) => {
        setUserDetails(
          res.data.users.find((data) => data._id === findData.user_id)
        );
      })
      .catch((err) => {});
  }, []);

  const depositeApproaval = (e) => {
    const data = {
      data: e,
    };
    Axios.post(`/update-withdraw`, data, config)
      .then((res) => {
        window.location.reload();
      })
      .catch((err) => {});
  };

  return (
    <>
      <AdminHeader />
      <div className="container-fluid bg-color-change" data-aos={"fade-up"}>
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-2">
              <SideBar />
            </div>
            <div className="col-lg-10">
              {loader === true ? (
                <Loader />
              ) : (
                <div className="withdraw-space1">
                  {userDetails === undefined ? (
                    <div className="text-center">
                      <h4 style={{ color: "#000" }} className="text-center">
                        No User Details Find
                      </h4>
                    </div>
                  ) : (
                    <>
                      <div className="left">
                        <h1 className="title-withdraw">Withdraw Information</h1>
                        <div className="item">
                          <div className="details">
                            <div className="detailItem">
                              <span className="itemKey">Account Name : </span>
                              <span className="itemValue">
                                {userData && userData.accountName}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Account Number : </span>
                              <span className="itemValue">
                                {userData && userData.accountNum}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">
                                Withdraw Amount :{" "}
                              </span>
                              <span className="itemValue">
                                {userData && userData.amount}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">Bank IFSC Code : </span>
                              <span className="itemValue">
                                {userData && userData.ifscCode}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">
                                Bank Mobile Number :{" "}
                              </span>
                              <span className="itemValue">
                                {userData && userData.number}
                              </span>
                            </div>
                            <div className="detailItem">
                              <span className="itemKey">UPI Id : </span>
                              <span className="itemValue">
                                {userData && userData.upiId}
                              </span>
                            </div>
                            <div className="detailItem  d-flex align-items-center">
                              <span className="itemKey">Status : </span>
                              <span className="itemValue">
                                {userData && userData.status === "pending" ? (
                                  <div className="status pending">
                                    {userData && userData.status}
                                  </div>
                                ) : userData && userData.status === "accept" ? (
                                  <div className="status accept">
                                    {userData && userData.status}
                                  </div>
                                ) : userData && userData.status === "reject" ? (
                                  <div className="status reject">
                                    {userData && userData.status}
                                  </div>
                                ) : null}
                              </span>
                            </div>
                            <div className="detailItem d-flex align-items-center">
                              <span className="itemKey">Action : </span>
                              {userData && userData.status === "pending" ? (
                                <div className="cellAction">
                                  <div
                                    className="viewButton1"
                                    onClick={() =>
                                      depositeApproaval({
                                        type: "accept",
                                        _id: userData && userData._id,
                                        userId: userData && userData.Userid,
                                        amount: userData && userData.amount,
                                      })
                                    }
                                  >
                                    success
                                  </div>
                                  <div
                                    className="deleteButton"
                                    onClick={() =>
                                      depositeApproaval({
                                        type: "reject",
                                        _id: userData && userData._id,
                                        userId: userData && userData.Userid,
                                        amount: 0,
                                      })
                                    }
                                  >
                                    reject
                                  </div>
                                </div>
                              ) : userData && userData.status === "accept" ? (
                                <div className="cellAction">
                                  <div className="viewButton1">
                                    successfully
                                  </div>
                                </div>
                              ) : userData && userData.status === "reject" ? (
                                <div className="cellAction">
                                  <div className="deleteButton">rejected</div>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div className="left">
                          <h1 className="title-withdraw">User Details</h1>
                          <div className="item">
                            <div className="details">
                              <div className="detailItem">
                                <span className="itemKey">User Email : </span>
                                <span className="itemValue">
                                  {userDetails && userDetails.email}
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">User Name : </span>
                                <span className="itemValue">
                                  {userDetails && userDetails.name}
                                </span>
                              </div>
                              <div className="detailItem">
                                <span className="itemKey">User Chips : </span>
                                <span className="itemValue">
                                  {userDetails && userDetails.balance}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithDrawDetails;
